<template>
  <div>
    <Multiselect
      v-model="choixTerritoire"
      :options="listeDepartements"
      :close-on-select="true"
      :clear-on-select="false"
      placeholder="Choisissez un département"
      label="nom"
      track-by="nom"
      :select-label="'Choisissez un département'"
    >
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label">
          {{ value.nom }}
        </div>
      </template>

      <template v-slot:option="{ option }">
        <div class="multiselect-single-label">
          {{ option.nom }}
        </div>
      </template>
    </Multiselect>
  </div>
  <pre class="language-json"><code>{{ choixTerritoire }}</code></pre>
</template>

<script>
import Multiselect from "vue-multiselect";
import liste_departements from "@/assets/donnees/liste_departements.json";

export default {
  name: "BarreRecherche",
  components: {
    Multiselect,
  },
  data() {
    return {
      choixTerritoire: null,
      listeDepartements: liste_departements,
    };
  },
  watch: {
    choixTerritoire(nouvelleVal) {
      if (nouvelleVal) {
        console.log("Nouvelle Val", nouvelleVal);
        this.openNewTab();
      }
    },
  },
  methods: {
    openNewTab() {
      this.$router.push({
        name: "Page Facteurs",
        params: { codeDepartement: this.choixTerritoire.code },
      });
      // You may need to use window.open() here instead, depending on your requirements
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
