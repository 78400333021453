import axios from "axios";

var Basic_API_URL = "https://observatoire-filieres.azurewebsites.net"
//Basic_API_URL = "https://lebasic.nohost.me/api/"
//Basic_API_URL = "http://localhost:7071"

var AxiosApiClient = null

function setNewApiUrl(newUrl){
  console.log("Setting api url from", Basic_API_URL, "to", newUrl)
  Basic_API_URL = newUrl;
  AxiosApiClient = axios.create({
    baseURL: Basic_API_URL,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

setNewApiUrl(Basic_API_URL);

var Homemade_Cache = {};

var Servers_Errors = {};

const axiosAbortControllers = {
  rechercheTerritoire: null,
}

async function serverCall(url, bodyRequest = null, abortController = null) {
  var apiClient = AxiosApiClient
  var requestUniqueId = url
  if (bodyRequest) {
    requestUniqueId += JSON.stringify(bodyRequest)
  }
  if (requestUniqueId in Homemade_Cache) {
    return JSON.parse(JSON.stringify(Homemade_Cache[requestUniqueId]))
  }
  try {
    var abortSignal = abortController ? abortController.signal : null
    if (bodyRequest) {
      let formdata = new FormData();
      for (var item in bodyRequest) {
        formdata.append(item, JSON.stringify(bodyRequest[item]));
      }
      var results = await apiClient.post(url, bodyRequest, {
        signal: abortSignal
      })
    }
    else {
      var results = await apiClient.get(url, {
        signal: abortSignal
      })
    }
  } catch (error) {
    if (error.code == "ERR_CANCELED") {
      console.log("serverCall canceled on url", url);
      return "cancelled"
    }
    console.log("serverCall Error", error, "on url", url);
    var message_to_display = `(${error.name}) ${error.message} : ${error.response.data.detail ? error.response.data.detail : error.response.data}`
    Servers_Errors[requestUniqueId] = message_to_display
    return null
  }
  if (requestUniqueId in Servers_Errors) {
    delete Servers_Errors[requestUniqueId]
  }
  Homemade_Cache[requestUniqueId] = results ? results.data : null;

  return JSON.parse(JSON.stringify(Homemade_Cache[requestUniqueId]))
}

function appendInlineParameters(url, parameters) {
  var firstParameter = true
  for (var parameter of parameters) {
    if (parameter.valeur != null) {
      var prefix = "&"
      if (firstParameter) {
        prefix = "?"
        firstParameter = false
      }
      url += `${prefix}${parameter.label}=${parameter.valeur}`
    }
  }
  return url
}


async function getDonneesFilosofiTerritoires(
  code_territoire,
  annee = null,
  niveau_detail_geographique = null
) {
  var url = `/filosofi/${code_territoire}`;
  var inline_parameters = [
    {label: "annee", valeur: annee},
    {label: "niveau_detail_geographique", valeur: niveau_detail_geographique},
  ]
  url = appendInlineParameters(url, inline_parameters)
  var result = await serverCall(url);
  return result;
}

async function chercheTerritoiresParNom(query, echelle_geographique) {
  if (axiosAbortControllers.rechercheTerritoire) {
    axiosAbortControllers.rechercheTerritoire.abort()
  }
  axiosAbortControllers.rechercheTerritoire = new AbortController()
  var url = `/parcel/GeoData/${query}`;
  var inline_parameters = [
    {label: "pays", valeur: "france"},
    {label: "type_territoire", valeur: echelle_geographique},
  ]
  url = appendInlineParameters(url, inline_parameters)
  var result = await serverCall(url, null, axiosAbortControllers.rechercheTerritoire);
  return result;
}

export default {
  getServerErrors() {
    return Servers_Errors
  },
  serverCall,
  setNewApiUrl,
  appendInlineParameters,
  getDonneesFilosofiTerritoires,
  chercheTerritoiresParNom,
};
