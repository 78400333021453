import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueEcharts from "vue-echarts";
import { VueShowdown } from "vue-showdown";
import VueMatomo from "vue-matomo";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import InlineSvg from "vue-inline-svg";

import "@/index.css";

createApp(App)
  .use(router)
  .use(VueMatomo, {
    host: "https://lebasic.nohost.me/matomo/",
    siteId: 1,
  })
  .component("v-charts", VueEcharts)
  .component("vue-showdown", VueShowdown, {
    options: {
      emoji: true,
      tables: true,
    },
  })
  .component("EasyDataTable", Vue3EasyDataTable)
  .component("inline-svg", InlineSvg)
  .mount("#app");

window._paq.push(["trackPageView"]); // Pour suivre les visites sur vos pages
