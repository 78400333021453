<template>
  <div id="menuDeroulant">
    <label for="elements" class="titre-petit titreMenu"> {{ titreMenu }}</label>
    <select
      name="elements"
      id="menuDeroulantComposant"
      v-model="elementChoisi"
      @change="
        $emit('update:modelValue', elementChoisi);
        console.log(modelValue);
      "
    >
      <option
        v-for="element in listeSousElements"
        :key="element"
        :value="element"
      >
        {{ element[this.cléAffichée] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Text,
    },
    titreMenu: {
      type: Text,
      default: "Titre du Nouveau Menu",
    },
    listeSousElements: {
      type: Array,
      default: ["Item 1", "Item 2", "Very very very very long Item 3"],
    },
    cléAffichée: {
      type: Text,
      default: "nomFacteur",
    },
    cléPourSélection: {
      type: Text,
      default: "lienAncre",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      SousElementsVisibles: false,
      elementChoisi: null,
    };
  },
  mounted() {
    this.elementChoisi = this.modelValue;
  },
  watch: {
    modelValue(nouvelleVal) {
      this.elementChoisi = nouvelleVal;
    },
  },
};
</script>
<style scoped>
#menuDeroulant {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#menuDeroulantComposant {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--dsem) 0px;
  border: solid 1px black;
}

.titreMenu {
  text-align: left;
}
</style>
