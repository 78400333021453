module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Risques liés à une information alimentaire insuffisante <a class=\"anchor\" href=\"#prsentation-du-facteur-de-risque\"></a>"
		},
		{
			"type": "text",
			"value": "Contrairement à certaines idées reçues, les mangeurs en situation de précarité alimentaire sont généralement bien informés sur ce qu’est une bonne alimentation. En revanche, certains peuvent manquer d’informations ou compétences permettant d’y accéder. Par exemple, le manque d’informations sur les lieux d’approvisionnement alimentaire à bon rapport qualité/prix ou sur les dispositifs d’aide existants (localisation, horaires, conditions d’accueil…) constitue un véritable frein dans l’accès à l’alimentation. Ensuite, les personnes peuvent se sentir limitées dans leur consommation du fait d’un manque de compétences culinaires pour préparer des aliments méconnus, principalement quand ils sont imposés dans le cadre de l’aide alimentaire. Les personnes  issues de classes populaires peuvent aussi ne pas sentir à l’aise dans certains espaces, comme les AMAP, plutôt fréquentées par des ménages favorisés. Enfin, un faible accès à une offre de soins permettant de recevoir des informations personnalisées sur le plan nutritionnel ou des difficultés de littératie en santé peuvent constituer des facteurs supplémentaires de risque de précarité alimentaire."
		},
		{
			"type": "text",
			"value": "## En savoir plus"
		},
		{
			"type": "list",
			"value": [
				"[Rey S., Leduc A., Debussche X., Rigal L., Ringa V., Costemalle  V. (2023) Une personne sur dix éprouve des difficultés de compréhension de l'information médicale. DREES. Etudes et résultats n° 1269](https://drees.solidarites-sante.gouv.fr/publications-communique-de-presse/etudes-et-resultats/une-personne-sur-dix-eprouve-des-difficultes)",
				"[Aschemann-Witzel, J., Zielke S., (2017), Can’t Buy Me Green? A Review of Consumer Perceptions of and Behavior Toward the Price of Organic Food. Journal of Consumer Affairs 51(1): 211‑251. doi: 10.1111/joca.12092.](https://onlinelibrary.wiley.com/doi/abs/10.1111/joca.12092)",
				"[Brocard, C., Saujot, M., Brimont, L., Dubuisson-Quellier, S., (2022), Pratiques alimentaires durables : un autre regard sur et avec les personnes modestes (no 01/22), Iddri.](https://www.iddri.org/fr/publications-et-evenements/decryptage/pratiques-alimentaires-durables-un-autre-regard-sur-et-avec)",
				"[Damhuis, L., Serré, A. & Rosenzweig, M., (2020), Concrétiser l’ambition démocratique de l’alimentation durable ?, Anthropology of food, n°S15.]( https://journals.openedition.org/aof/11372)",
				"[Darmon N, Drewnowski A., (2015), Contribution of food prices and diet cost to socioeconomic disparities in diet quality and health: a systematic review and analysis. Nutr Rev 73(10): 643-60. doi: 10.1093/nutrit/nuv027.](https://pubmed.ncbi.nlm.nih.gov/26307238/)"
			]
		},
		{
			"type": "text",
			"value": "## Présentation des indicateurs utilisés"
		},
		{
			"type": "text",
			"value": "### Part des personnes non scolarisées de 15 ou plus titulaires au plus d'un brevet des collèges (%)"
		},
		{
			"type": "text",
			"value": "Echelles concernées : Communes, IRIS<br>Source de données : Insee, RP 2008-2013-2019 (open data)"
		},
		{
			"type": "text",
			"value": "### Indicateur d’accessibilité potentielle localisée à l’offre de soins"
		},
		{
			"type": "text",
			"value": "Echelle concernée : Communes<br>Source de données : DREES (open data)<br>[En savoir plus](https://drees.solidarites-sante.gouv.fr/sources-outils-et-enquetes/lindicateur-daccessibilite-potentielle-localisee-apl)"
		},
		{
			"type": "text",
			"value": "### Densité de l'offre de conseils en alimentation santé et nutrition (généralistes, diététiciens, centres de santé) pour 1000 habitants"
		},
		{
			"type": "text",
			"value": "Echelle concernée : IRIS<br>Base de données : Base permanente des équipements (2021) et Recensement de population (2020) - INSEE (open data)"
		}
	],
	"verbatim": [
		{
			"type": "text",
			"value": "## Le manque d'information en mots"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": "> *« On manque de moyens, des fois même d’informations, mais c’est un manque de moyens dans le sens où on n’est pas formés… »*"
		},
		{
			"type": "text",
			"value": "> *« Mais aussi être informé correctement, parce que, des fois, on a l’impression que c’est de la publicité, alors que ça a une relation avec la santé de chacun. »*"
		},
		{
			"type": "text",
			"value": "> *« Oui, un atelier cuisine comme ils avaient fait dans le passé pourrait être bénéfique pour ceux qui savent pas trop se nourrir, qui ont pas appris ce que c’est d’équilibrer telle et telle chose, ou pour ceux qui n’ont pas les moyens. »*"
		}
	]
};