<template>
  <!-- <pre v-if="zoomCarte && !carteChargement && geojson">
    {{ zoomCarte }}
  </pre> -->
  <div style="height: 100%; width: 100%">
    <l-map
      ref="map"
      style="height: 100%; width: 100%; min-height: 200px"
      @update:zoom="boundUpdate"
      @update:bounds="boundUpdate"
      :options="{ preferCanvas: true }"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-geo-json
        v-if="geojson && !carteChargement"
        @ready="cartePrete"
        noBlockingAnimations="true"
        :geojson="geojson"
        :options="options"
        :optionsStyle="optionStyle"
        :key="geojson"
      />
      <slot name="contenu"></slot>
    </l-map>
  </div>
  <div id="legende" v-if="montrerLegende">
    <h3>Niveau de risque de précarité alimentaire</h3>
    <ul id="legende-couleurs">
      <li v-for="couleur in couleursPalette" :key="couleur">
        <div
          class="couleur"
          :style="{ backgroundColor: couleur.couleur }"
        ></div>
        <div class="label">{{ couleur.label }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LGeoJson } from "@vue-leaflet/vue-leaflet";

export default {
  name: "carteChloropeth",
  props: {
    code_du_territoire: String,
    zoom: {
      type: Number,
      default: null,
    },
    geojson: {
      type: Object,
      default: undefined,
    },
    carteChargement: {
      type: Boolean,
      default: false,
    },
    onEachFeatureFunction: Function,
    choisirCouleur: {
      Function,
      default: "#FF3333",
    },
    couleurBordure: {
      type: String,
      default: "#800F2F",
    },
    epaisseurBordure: {
      type: Number,
      default: 0.5,
    },
    couleursPalette: {
      type: Array,
      default: [{ couleur: "#FF3333", label: "Très élevé" }],
    },
    montrerLegende: {
      type: Boolean,
      default: false,
    },
    boundingBox: {
      type: Object,
      default: null,
      required: false,
    },
    attribution: {
      type: String,
      default:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
    url: {
      type: String,
      default:
        "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
    },
  },
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  data() {
    return {
      zoomCarte: null,
      enableTooltip: true,
      optionStyle: (feature) => ({
        weight: this.epaisseurBordure,
        color: this.couleurBordure,
        opacity: 1,
        fillOpacity: 0.8,
        fillColor: this.choisirCouleur(feature),
      }),
    };
  },
  methods: {
    ajouterLabels() {
      const mapObjet = this.$refs.map.leafletObject;
      mapObjet.createPane("labels");
      mapObjet.getPane("labels").style.zIndex = 650;
      mapObjet.getPane("labels").style.pointerEvents = "none";
      console.log("mounted");
      L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png",
        {
          attribution: "©OpenStreetMap, ©CartoDB",
          pane: "labels",
        }
      ).addTo(mapObjet);
    },
    trouverCentre() {
      if (this.boundingBox) {
        this.$refs.map.leafletObject.fitBounds(this.boundingBox);
      } else {
        const contour = L.geoJSON(this.geojson).getBounds();
        this.$refs.map.leafletObject.fitBounds(contour);
      }
    },
    cartePrete() {
      this.trouverCentre();
      this.ajouterLabels();
    },
    zoomUpdate() {
      console.log("zoomUpdate");
      this.$emit("update:zoom", this.$refs.map.leafletObject.getBounds());
    },
    boundUpdate() {
      console.log("boundUpdate");
      this.$emit("update:bounds", this.$refs.map.leafletObject.getBounds());
    },
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
  },
  watch: {
    geojson() {
      console.log("geojson: trouverCentre");
      this.trouverCentre();
    },
  },
};
</script>
