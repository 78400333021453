module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Diversité des risques pouvant conduire à des situations de précarité alimentaire"
		},
		{
			"type": "text",
			"value": "La précarité alimentaire est multifactorielle. Elle peut être causée et se maintenir du fait de contraintes économiques, d’un isolement social, d’un environnement alimentaire appauvri, de difficultés d’accès à de l’information alimentaire ou encore de freins pratiques (manque d’accès à des équipements et à un endroit adéquats pour cuisiner, souvent lié à des conditions de logement dégradées)."
		},
		{
			"type": "text",
			"value": "L’approche combinée proposée par cet outil permet de mettre en évidence les territoires à risque de précarité alimentaire du fait d’un cumul de facteurs de risque. Elle est basée sur un calcul qui prend en compte les quatre premières catégories précédemment citées (voir onglets ci-contre). La dimension “pratique” (liée notamment au manque d’équipement) n’a pas pu être intégrée car il n’existe pas d’indicateurs en libre accès à l’échelle territoriale permettant de renseigner cette contrainte. Par ailleurs, la contrainte économique s’est vue attribuer deux fois plus d’importance que les autres catégories de facteurs de risque, en considérant que c’est celle qui entrave le plus directement l’accès à une alimentation de qualité."
		}
	]
};