<template>
  <grilleStandard>
    <template v-slot:contenu>
      <div class="contenu">
        <div class="titre-sous-titre">
          <div style="padding-bottom: var(--dsem2); text-align: left">
            <h1 class="couleur-primaire-sombre titre">
              Données des facteurs de risque de précarité alimentaire
            </h1>
            Choisissez un département et un facteur de risques afin de
            visualiser la carte correspondante.
          </div>
          <h2 class="couleur-primaire">
            <b>Choisissez un département :</b>
          </h2>
        </div>
        <barreRechercheDepartements
          @departementChoisi="
            departementChoisi = $event;
            departementChoisi.code_territoire = 'dep' + $event.code;
          "
        ></barreRechercheDepartements>
        <div
          :class="{
            'bloc-facteur': true,
            'est-cache': codeDepartement === null ? true : false,
          }"
        >
          <h2 class="couleur-primaire" style="font-weight: bold">
            Choisissez une catégorie de facteurs de risque :
          </h2>
          <div id="listeCartesFacteurs">
            <div
              v-for="facteur in facteursRisqueListe.filter(
                (facteur) => facteur.lienAncre !== 'synthetique'
              )"
              :key="facteur"
            >
              <carteFacteur
                :nomFacteur="facteur.nomFacteur"
                :ancreFacteur="facteur.lienAncre"
                :class="facteur.lienAncre"
                :couleurFond="facteur.couleur"
                :imageFacteur="facteur.image"
                :codeDepartement="codeDepartement"
              ></carteFacteur>
            </div>
            <div id="carte-facteur-synthetique">
              <h4 class="facteur-titre">
                <b>Approche combinée</b><br />
                <router-link
                  :to="{
                    path: '/indicateurs',
                    query: { facteur: 'synthetique' },
                  }"
                  class="boutonDoc"
                  >> En savoir plus</router-link
                >
              </h4>
              <div>
                <inline-svg
                  class="logo"
                  :src="require('@/assets/icons/synthetique.svg')"
                  fill="black"
                  height="40"
                />
              </div>
              <div class="boutons" v-if="codeDepartement">
                <BoutonStandard
                  type="encadre"
                  couleur="couleur-accent"
                  :lienRoute="{
                    name: 'Dashboard Territoire',
                    params: { codeDepartement: codeDepartement },
                    query: { ancreFacteur: 'synthetique' },
                  }"
                  >Voir la carte</BoutonStandard
                >
              </div>
            </div>
          </div>
        </div>
      </div></template
    ></grilleStandard
  >
</template>

<script>
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
import barreRechercheDepartements from "@/composants/barreRechercheDepartements.vue";
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import carteFacteur from "@/composants/carteFacteur.vue";
import facteurs_de_risques from "@/assets/donnees/facteurs_de_risques.json";
export default {
  name: "pageCartographie",
  components: {
    grilleStandard,
    barreRechercheDepartements,
    carteFacteur,
    BoutonStandard,
  },
  data() {
    return {
      departementChoisi: null,
      facteursRisqueListe: facteurs_de_risques,
      codeDepartement: null,
    };
  },
  watch: {
    departementChoisi: {
      handler: function (val) {
        if (val) {
          this.codeDepartement = val.code_territoire.slice(3);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.contenu {
  height: calc(100vh - var(--hauteur-barre-navigation));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titre-sous-titre {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: var(--dsem4);
}
#listeCartesFacteurs {
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  grid-template-rows: minmax(200px, 1fr) minmax(50px, 180px);
  max-width: 1024px;
  max-height: 420px;
  margin: 0 auto;
  justify-items: stretch;
  align-items: stretch;
  gap: var(--dsem2);
  padding-top: var(--dsem2);
}

#listeCartesFacteurs div:last-child {
  grid-column-start: 1;
  grid-column-end: 5;
}

.synthetique {
  background-color: var(--couleur-primaire);
  color: var(--couleur-neutre-clair);
}

#carte-facteur-synthetique {
  background-color: #b9e3e3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.bloc-facteur {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: var(--dsem2);
}
.est-cache {
  opacity: 0.3;
}
</style>
