<template>
  <div id="legende">
    <h3>{{ titre }}</h3>
    <ul id="legende-couleurs" :style="{ 'flex-direction': sens }">
      <li v-for="couleur in couleursPalette" :key="couleur">
        <div
          :class="[couleur, couleur.type !== 'carré' ? couleur.type : 'carré']"
          :style="{
            backgroundColor: couleur.couleur,
            'border-color': couleur.couleur,
          }"
        ></div>
        <div class="texte-petit">{{ couleur.label }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { color } from "echarts";

export default {
  name: "legendeChloropeth",
  props: {
    titre: {
      type: String,
      default: "Niveau de risque de précarité alimentaire",
    },
    couleursPalette: {
      type: Array,
      default: [{ couleur: "#FF3333", label: "Très élevé", type: "carré" }],
    },
    sens: {
      type: String,
      default: "row",
    },
  },
};
</script>

<style scoped>
#legende {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: var(--dsem);
}

#legende-couleurs {
  display: flex;
  gap: var(--dsem);
}

#legende ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--dsem);
  align-items: center;
}

.couleur {
  height: 16px;
  width: 16px;
  border: 1px solid;
}

.rond {
  border-radius: 50%;
}

.carré-vide {
  background-color: white !important;
}
</style>
