<template>
  <grilleStandard>
    <template v-slot:contenu>
      <div>
        <div v-if="archieData" class="content">
          <template v-for="(contenu, index) in archieData.content" :key="index">
            <vue-showdown
              v-if="contenu.type == 'text'"
              :markdown="contenu.value"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'list'"
              :markdown="concateneList(contenu.value)"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'table'"
              tables="true"
              :markdown="concateneTable(contenu.value)"
              :options="{ tables: true }"
              class="methodologie"
            ></vue-showdown>
          </template>
          <img
            :src="require('@/assets/img/schema.png')"
            alt="indicateurs"
            width="800"
            style="margin: auto; display: block"
          />
          <template
            v-for="(contenu, index) in archieData.content2"
            :key="index"
          >
            <vue-showdown
              v-if="contenu.type == 'text'"
              :markdown="contenu.value"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'list'"
              :markdown="concateneList(contenu.value)"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'table'"
              tables="true"
              :markdown="concateneTable(contenu.value)"
              :options="{ tables: true }"
              :class="test"
              class="methodologie"
            ></vue-showdown>
          </template>
          <table>
            <thead class="bg-gray-50">
              <tr>
                <th style="text-align: center">Catégories de risques</th>
                <td>Contrainte repérée dans la littérature</td>
                <td>Indicateurs à l’échelle communale</td>
                <td>Indicateurs à l’échelle IRIS</td>
              </tr>
            </thead>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                style="
                  background-color: #00a8a8;
                  color: white;
                  text-align: center;
                "
              >
                Contraintes économiques
                <p class="texte-petit">
                  <router-link
                    :to="{
                      name: 'Page Indicateurs',
                      query: { facteur: 'monetaire' },
                    }"
                    >(en savoir plus)</router-link
                  >
                </p>
              </th>
              <td>
                Ressources économiques restreintes ne permettant pas de se
                procurer une alimentation choisie et de qualité
              </td>
              <td>Médiane du revenu disponible par unité de conso. (€)</td>
              <td>1er quartile du revenu disponible par unité de conso. (€)</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                rowspan="5"
                style="
                  background-color: #006666;
                  color: white;
                  text-align: center;
                "
              >
                Isolement social
                <p class="texte-petit">
                  <router-link
                    :to="{
                      name: 'Page Indicateurs',
                      query: { facteur: 'socio_eco' },
                    }"
                    >(en savoir plus)</router-link
                  >
                </p>
              </th>
              <td rowspan="5">
                Solitude, manque de soutien social et/ou exclusion sociale
                pouvant conduire à un appauvrissement alimentaire
              </td>
              <td>Taux de chômage des 15-64 ans (%)</td>
              <td>Taux de chômage des 15-64 ans (%)</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>Part des ménages monoparentaux (%)</td>
              <td>Part des ménages monoparentaux (%)</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>Part des ménages de une personne (%)</td>
              <td>Part des ménages de une personne (%)</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>Part des jeunes non insérés</td>
              <td></td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>Part des plus de 75 ans (%)</td>
              <td>Part des plus de 75 ans (%)</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                rowspan="3"
                style="
                  background-color: #b58619;
                  color: white;
                  text-align: center;
                "
              >
                Environnement alimentaire appauvri
                <p class="texte-petit">
                  <router-link
                    :to="{
                      name: 'Page Indicateurs',
                      query: { facteur: 'mobilite' },
                    }"
                    >(en savoir plus)</router-link
                  >
                </p>
              </th>
              <td rowspan="3">
                Accès physique contraint à des lieux d’approvisionnement
                alimentaire
              </td>
              <td>Taux de non motorisation des ménages (%)</td>
              <td>Densité de GMS pour 1000 habitants (magasins)</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>Temps d’accès à la GMS la + proche (s)</td>
              <td>
                Densité de supérettes et épiceries pour 1000 habitants
                (magasins)
              </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td></td>
              <td>
                Score de diversité offre alimentaire (dont commerces spécialisés
                et restaurants)
              </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                rowspan="2"
                style="
                  background-color: #f9b000;
                  color: white;
                  text-align: center;
                "
              >
                Information alimentaire insuffisante
                <p class="texte-petit">
                  <router-link
                    :to="{
                      name: 'Page Indicateurs',
                      query: { facteur: 'litteracie' },
                    }"
                    >(en savoir plus)</router-link
                  >
                </p>
              </th>
              <td rowspan="2">
                Manque d'informations et/ou de compétences pouvant limiter la
                qualité de l'alimentation
              </td>
              <td>
                Part des personnes non scolarisées de 15 ou plus titulaires au
                plus d'un brevet des collèges (%)
              </td>
              <td>
                Part des personnes non scolarisées de 15 ou plus titulaires au
                plus d'un brevet des collèges (%)
              </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>Accessibilité potentielle localisée à l’offre de soins</td>
              <td>
                Densité de l'offre de conseils en alimentation santé et
                nutrition (généralistes, diététiciens, centres de santé) pour
                1000 habitants
              </td>
            </tr>
          </table>
          <template
            v-for="(contenu, index) in archieData.content3"
            :key="index"
          >
            <vue-showdown
              v-if="contenu.type == 'text'"
              :markdown="contenu.value"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'list'"
              :markdown="concateneList(contenu.value)"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'table'"
              tables="true"
              :markdown="concateneTable(contenu.value)"
              :options="{ tables: true }"
              class="methodologie"
            ></vue-showdown>
          </template>
          <template
            v-for="(contenu, index) in archieData.content4"
            :key="index"
          >
            <vue-showdown
              v-if="contenu.type == 'text'"
              :markdown="contenu.value"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'list'"
              :markdown="concateneList(contenu.value)"
              class="methodologie"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'table'"
              tables="true"
              :markdown="concateneTable(contenu.value)"
              :options="{ tables: true }"
              class="methodologie"
            ></vue-showdown>
          </template>
        </div>
      </div>
    </template>
  </grilleStandard>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import "archieml";
import globalMethodologie from "@/assets/description_indicateurs/descriptionMethodologie.archieml";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";

export default {
  name: "IndicateursVIew",
  components: {
    VueShowdown,
    "vue-showdown": VueShowdown,
    grilleStandard,
  },
  data() {
    return {
      archieData: null,
      facteurs: null,
    };
  },
  mounted() {
    this.logArchieMlContenu();
  },
  methods: {
    logArchieMlContenu() {
      console.log("=> Log contenu");
      const archieData = globalMethodologie;
      this.archieData = archieData;
      console.log(this.archieData);
      return this.archieData;
    },
    concateneList(list) {
      var text = "";
      for (let i = 0; i < list.length; i++) {
        text += "* " + list[i] + "\n";
      }
      console.log(text);

      return text;
    },
    concateneTable(list) {
      var text = "";
      for (let i = 0; i < list.length; i++) {
        text += "" + list[i] + "\n";
      }
      console.log(text);

      return text;
    },
  },
};
</script>

<style scoped>
.content {
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
  padding: 32px 0px;
}

.methodologie :deep(h1) {
  color: var(--couleur-primaire-sombre);
  line-height: 1.2;
}

.methodologie :deep(h2) {
  color: var(--couleur-primaire);
  font-weight: bold;
}

.methodologie :deep(*) {
  line-height: 20px;
}
.methodologie :deep(a) {
  color: var(--couleur-primaire) !important;
  text-decoration: underline !important;
}

.methodologie :deep(ul) {
  list-style-type: disc;
  list-style-position: inside;
}
</style>
