<template>
  <grilleStandard>
    <template v-slot:contenu>
      <div class="contenant">
        <div style="text-align: left">
          <h1 class="titre couleur-primaire-sombre">Formulaire de contact</h1>
          <p>
            Vous avez une question ou une remarque concernant cet outil ? Vous
            constatez un dysfonctionnement ? N'hésitez pas à nous en faire part
            via le formulaire ci-dessous :
          </p>
        </div>
        <form ref="form" @submit.prevent="envoyerEmail">
          <p
            style="
              border: 1px solid var(--couleur-jaune);
              background-color: var(--couleur-jaune-60);
              text-align: center;
              padding: var(--dsem);
              color: var(--couleur-jaune);
              font-weight: 500;
            "
            v-if="alerteVisible"
          >
            Veuillez renseigner tous les champs pour pouvoir être recontacté
            ultérieurement
          </p>
          <label for="nomContact">Votre nom</label>
          <input
            type="text"
            id="nomContact"
            name="nomContact"
            v-model="nomContact"
            placeholder="Votre nom"
          />

          <label for="emailContact">Votre email</label>
          <input
            type="text"
            id="emailContact"
            name="emailContact"
            v-model="emailContact"
            placeholder="Votre email"
          />
          <label for="message">Votre message</label>
          <textarea
            id="message"
            name="message"
            v-model="message"
            placeholder="Ecrivez votre message"
            style="height: 200px"
          ></textarea>
          <BoutonStandard
            typeBouton="input"
            valeurInput="Envoyez votre message"
          />
        </form>
      </div>
    </template>
  </grilleStandard>
</template>

<script>
import emailjs from "emailjs-com";
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";

export default {
  name: "pageContact",
  components: {
    BoutonStandard,
    grilleStandard,
  },
  data() {
    return {
      nomContact: "",
      emailContact: "",
      message: "",
      alerteVisible: false,
    };
  },
  methods: {
    envoyerEmail(e) {
      if (this.emailContact && this.nomContact && this.message) {
        try {
          emailjs.sendForm(
            "service_zujc69b",
            "template_felxgge",
            e.target,
            "2JRD_e4kBVFV5XJeQ",
            {
              name: this.name,
              email: this.email,
              message: this.message,
            }
          );
        } catch (error) {
          console.log({ error });
        }
        // Reset form field
        this.nomContact = "";
        this.emailContact = "";
        this.message = "";
        this.alerteVisible = false;
      } else {
        console.log("Veuillez remplir tous les champs");
        alert("Veuillez remplir tous les champs");
        this.alerteVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.titre {
  font-weight: bold;
  padding-top: var(--dsem4);
}
.contenant {
  margin: 0;
  height: 100%;
  display: flex;
  gap: var(--dsem);
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: left;
}

input,
textarea {
  border: 1px solid rebeccapurple;
  padding: var(--dsem);
  margin: var(--dsem) 0;
}
</style>
