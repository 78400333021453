<template>
  <div id="piedPage">
    <h2>Les partenaires du projet</h2>
    <div id="logoPartenaires">
      <img
        :src="require(`@/assets/sponsors/ADM_logo.png`)"
        style="padding: 20px 0; padding-right: 10px"
      />
      <img
        :src="require(`@/assets/sponsors/AGRO_logo.svg`)"
        style="padding: 20px 0"
      />
      <img :src="require(`@/assets/sponsors/cirad.png`)" />
      <img
        :src="require(`@/assets/sponsors/Logo-INRAE_Transparent.svg`)"
        style="padding-right: 10px"
      />
      <img
        :src="require(`@/assets/sponsors/BASIC_logo.svg`)"
        style="padding: 20px 0"
      />
      <img
        :src="require(`@/assets/sponsors/occitanie.png`)"
        style="padding: 10px 0; padding-left: 10px"
      />
      <img
        :src="
          require(`@/assets/sponsors/Logo_Montpellier_Méditerranée_Métropole.svg`)
        "
      />
      <img
        :src="require(`@/assets/sponsors/herault.png`)"
        style="padding: 20px 20px"
      />

      <img :src="require(`@/assets/sponsors/Préfet_de_l'Hérault.svg`)" />
      <img
        :src="require(`@/assets/sponsors/Préfet_de_la_région_Occitanie.svg`)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PiedPage",
};
</script>

<style scoped>
#piedPage {
  padding: var(--dsem2);
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 100%
    ),
    url(https://obso-alim.org/wp-content/uploads/2023/02/Fond-gris.jpg) !important;
  height: 200px;
}

#logoPartenaires {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  justify-content: center;
}

#logoPartenaires img {
  width: auto;
  max-height: 100px;
  margin: auto;
}
</style>
