<template>
  <div id="pageAccueil">
    <div id="infographie">
      <div style="height: 100%; display: flex; flex-grow: 1">
        <infographieVulnerabilite class="infographie" />
      </div>
    </div>
    <div id="presentationProjet" style="justify-content: center">
      <AccueilRecherche
        titre="Diagnostic de précarité alimentaire à l'échelle territoriale"
        sousTitre="Obtenez une cartographie des niveaux de risque de précarité alimentaire dans les communes et les quartiers de votre département"
        class="accueilRecherche"
      >
        <template v-slot:barre-recherche>
          <BarreRecherche />
        </template>
      </AccueilRecherche>
    </div>
  </div>
  <pied-page />
</template>

<script>
import BarreRecherche from "@/composants/barreRecherche.vue";
import AccueilRecherche from "@packages/ui-components/src/composants/Accueil+Recherche.vue";
import PiedPage from "@/composants/PiedPage.vue";
import infographieVulnerabilite from "@/composants/infographieVulnerabilite.vue";

export default {
  name: "pageAccueil",
  components: {
    AccueilRecherche,
    BarreRecherche,
    PiedPage,
    infographieVulnerabilite,
  },
};
</script>
<style scoped>
#pageAccueil {
  display: flex;
  flex-direction: column-reverse;
}

#infographie {
  flex-grow: 2;
  flex-basis: 600px;
  display: flex;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  #pageAccueil {
    height: calc(100vh - var(--hauteur-barre-navigation));
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgba(229, 222, 212, 0.6);
  }

  #presentationProjet {
    display: flex;
    padding: var(--dsem4);
  }
}
.accueilRecherche {
  padding: 0 var(--dsem4) !important;
}
.accueilRecherche >>> h1 {
  max-width: 422px;
  margin: auto;
}
.accueilRecherche >>> h2 {
  max-width: 580px;
  line-height: 30px;
  font-weight: bold;
  margin: auto;
}
</style>
