module.exports = {
	"content": [
		{
			"type": "text",
			"value": "# Méthodologie employée"
		},
		{
			"type": "text",
			"value": "##Principe général"
		},
		{
			"type": "text",
			"value": "La caractérisation de la précarité alimentaire proposée par cet outil repose sur trois piliers :"
		},
		{
			"type": "list",
			"value": [
				"1/ **Une approche territoriale** de la précarité alimentaire (communes et quartiers des grandes communes, appelés les [IRIS](https://www.insee.fr/fr/information/2017499)) ;",
				"2/ **Une lecture multidimensionnelle** la précarité alimentaire, qui s’intéresse à ses diverses causes potentielles (c’est pourquoi on parle de « risques », liés à des contraintes économiques mais aussi à l’isolement social, à un environnement alimentaire appauvri ou encore à des difficultés d’accès à l’information) ;",
				"3/ Un fonctionnement **pérenne et utilisable dans tous les territoires de France hexagonale**."
			]
		}
	],
	"content2": [
		{
			"type": "text",
			"value": "## Choix des indicateurs"
		},
		{
			"type": "text",
			"value": "Cet outil produit des cartographies des risques de précarité alimentaire à partir de **différents indicateurs**. Au regard des trois principes qui régisse le fonctionnement de l’outil (voir ci-dessus), ces indicateurs doivent refléter les différentes causes de la précarité alimentaire, être mesurés à l’échelle territoriale et que leurs résultats soient disponibles en libre accès en ligne et régulièrement actualisés."
		},
		{
			"type": "text",
			"value": "En croisant la littérature scientifique sur les différentes catégories de facteurs de risque de précarité alimentaire et les bases de données publiques de [l’Insee](https://www.insee.fr/fr/information/1302198) et de la [BPE](https://www.data.gouv.fr/fr/datasets/base-permanente-des-equipements-1/), une dizaine d’indicateurs appartenant à **quatre catégories de facteurs de risque** ont été sélectionnés :"
		}
	],
	"content3": [
		{
			"type": "text",
			"value": "A noter que selon l’échelle envisagée (commune ou IRIS), les indicateurs utilisés ne sont pas exactement les mêmes, soit parce que certains indicateurs ne sont pas disponibles à l’une ou l’autre des échelles en raison du secret statistique (comme c’est le cas pour les indicateurs de contraintes économiques), soit pour une question de pertinence par rapport à l’échelle considérée. Par exemple, les indicateurs concernant l’environnement alimentaire diffèrent pour rendre compte des risques de précarité alimentaire en milieu plutôt rural pour l’échelle communale, et en milieu plutôt urbain à l’échelle IRIS."
		},
		{
			"type": "text",
			"value": "## Echelle de comparaison"
		},
		{
			"type": "text",
			"value": "Les cartographies peuvent être réalisées en utilisant des échelles de comparaison différentes (locale ou nationale). Le tableau ci-dessous indique ce qu’implique le choix de l’une ou l’autre de ces échelles."
		},
		{
			"type": "list",
			"value": [
				"Echelle de référence départementale : on compare la valeur de l’indicateur pour la commune ou l’IRIS choisi(e) aux valeurs prises par cet indicateur dans l’ensemble des communes ou IRIS du même département",
				"Echelle de référence nationale : on compare la valeur de l’indicateur pour la commune ou l’IRIS choisi(e) aux valeurs prises par cet indicateur dans l'ensemble des communes ou IRIS de France"
			]
		}
	],
	"content4": [
		{
			"type": "text",
			"value": "## Méthode de calcul"
		},
		{
			"type": "text",
			"value": "Pour chaque facteur de risque, les différents niveaux de risque sont obtenus en utilisant une méthode de discrétisation de données que l’on appelle **la méthode « des seuils naturels »**. Elle permet, grâce à l’utilisation de l’algorithme dit « de [Jenks](https://en.wikipedia.org/wiki/Jenks_natural_breaks_optimization) », de créer des classes homogènes (ici 5 classes) en regroupant entre elles les valeurs similaires d’une série de données (concernant soit toutes les communes d’un département ou tous les IRIS d’une commune, soit toutes les communes ou les IRIS de France, selon l’échelle choisie) et en maximisant les différences entre les classes. Pour chaque commune ou IRIS, les indicateurs se voient alors associer un score d’une valeur entière comprise entre 0 et 1, 1 caractérisant les situations où le risque de précarité alimentaire est le plus élevé (par exemples : revenus très faibles, taux de chômage élevé, grande distance entre le domicile et la GMS la plus proche…)."
		},
		{
			"type": "text",
			"value": "Pour chaque indicateur agrégé d'une catégorie de facteurs de risque, un indice qui est la moyenne du classement inter-décile sur chaque indicateur de la catégorie a été calculé. Cette moyenne est ensuite normalisée pour obtenir un score compris entre 0 et 1, à partir duquel on peut définir des classes de risque grâce à la méthode de Jenks."
		},
		{
			"type": "text",
			"value": "Pour chaque catégorie de facteurs de risque, l’outil permet de visualiser une carte du risque de précarité alimentaire résultant d’un cumul des différents facteurs de risque d’une même catégorie. Ce niveau de risque est obtenu en réalisant une moyenne des scores des indicateurs de la catégorie. Ainsi, pour la catégorie « isolement social », la valeur de l’indice correspond à la moyenne des scores des cinq indicateurs de cette catégorie. Cet indice par catégorie a lui aussi une valeur comprise entre 0 et 1. L’outil permet de générer des cartes spécifiques à chaque catégorie de risque contrastant les zones selon leur score : a) comparant les communes à l’échelle nationale ; b) les comparant à l’échelle de leur département ; c) comparant les IRIS à l’échelle nationale ; d) les comparant à l’échelle de leur commune."
		},
		{
			"type": "text",
			"value": "Enfin, l’outil permet de produire une cartographie du risque de précarité alimentaire résultant d’un cumul des différents facteurs de risque des quatre catégories (« [approche combinée](/indicateurs?facteur=synthetique) »). Ce niveau de risque « combiné » est obtenu à partir d’une moyenne pondérée des indices des quatre catégories de facteurs de risque, en utilisant les pondérations suivantes : un coefficient de 2 à l’indice de l’axe « contraintes économiques » (représentant donc 40% de l’indice synthétique) et un coefficient de 1 à l’indice de chacune des trois autres catégories (représentant donc chacun 20% de l’indice synthétique). Ce choix de pondération vise à refléter le poids des contraintes économiques dans les situations de précarité alimentaire. On obtient alors pour chaque commune ou IRIS un indice synthétique de risque de précarité alimentaire dont la valeur est à nouveau comprise entre 1 et 5. L’outil permet de générer les cartes de cet indicateur agrégé, là encore soit en comparaison à l’échelle nationale, soit aux échelles du département (pour les communes) ou de la commune (pour les IRIS)."
		},
		{
			"type": "text",
			"value": "## Estimation pour lever le secret statistique"
		},
		{
			"type": "text",
			"value": "Certains indicateurs (notamment monétaires) présentent des valeurs manquantes pour certaines communes ou IRIS, en raison du secret statistique. Pour pallier ce problème, une estimation de ces valeurs manquantes a été réalisée. Cette estimation a été effectuée en se basant sur les communes avoisinantes. En général, la commune présentant le plus haut risque de précarité alimentaire a été choisi comme référence pour l’estimation."
		},
		{
			"type": "text",
			"value": "## Précautions liées à la méthode"
		},
		{
			"type": "text",
			"value": "La méthode décrite ci-dessus présente **quelques limites** dont les utilisateurs de l’outil doivent avoir conscience :"
		},
		{
			"type": "list",
			"value": [
				"Les indicateurs utilisés ne rendent qu’imparfaitement compte de l’ensemble des facteurs de risque de la précarité alimentaire. Pour avoir une approche plus fine, il faudrait mobiliser d’autres indicateurs (sur le logement, l’équipement des ménages, le ressenti de leur situation par les personnes…) qui n’ont pu être intégrés, soit parce qu’ils n’existent pas, soit parce qu’ils existent à l’échelle nationale mais pas à l’échelle locale, soit parce que, bien qu’existant à l’échelle locale, ils sont soumis au secret statistique et ne sont donc pas disponibles en libre accès.",
				"La formation des différentes classes de risques selon l’algorithme de Jenks donne lieu à des niveaux de risque relatifs. Ainsi, les communes classées en « risque le plus élevé » n'ont pas forcément des hauts niveaux de précarité alimentaire, mais présentent plus de risques que les autres communes de leur département. Ce sont donc a priori vers ces communes que des actions de lutte contre la précarité alimentaire doivent être entreprises en priorité. Par ailleurs, la valeur absolue des différents indicateurs (et sa comparaison aux moyennes départementales et nationales) permet d’avoir une idée de la portée réelle du risque.",
				"Ceci implique qu’il n’est pas possible de comparer le niveau de risque de deux communes si l’échelle de référence est l’échelle départementale. En effet, la situation d’une commune classée en « risque le plus élevé » dans un département cumulant peu de facteurs de risque (comme les Hauts de Seine) ne sera pas la même que celle d’une commune classée en « risque le plus élevé » dans un département cumulant divers facteurs de risque (comme la Seine Saint Denis). En revanche, cette comparaison est possible en utilisant l’échelle de référence nationale.",
				"Afin de rendre compte de l’importance de la contrainte budgétaire dans les situations de précarité alimentaire, il a été choisi de donner plus de poids à l’indicateur économique (via un système de pondération) dans le calcul de l’indice global (approche pondérée). Le choix de la pondération a été ajusté à la suite de discussions avec des acteurs de terrain, mais ne s’appuie pas sur un travail académique préexistant.",
				"Par ailleurs, la construction de certains indicateurs (ex : temps d'accès à la GMS la plus proche, taux de chômage à l'échelle IRIS) n'est pas totalement satisfaisante et doit faire l'objet d'une optimisation dans un retravail méthodologique futur"
			]
		},
		{
			"type": "text",
			"value": "Pour ces différentes raisons, cet outil représente une aide au diagnostic de la précarité alimentaire à l’échelle territoriale, sans pour autant se suffire à lui-même : son utilisation, donnant un premier panorama de la situation, gagnera à être complétée par des enquêtes de terrain afin de préciser, avec d’autres données quantitative et qualitative, la nature concrète des risques de précarité alimentaire sur un territoire donné."
		},
		{
			"type": "text",
			"value": "## Références"
		},
		{
			"type": "list",
			"value": [
				"Mousty A., 2019. L’indice de précarité alimentaire comme outil de diagnostic, Forum 2019/3 n°158",
				"Paturel D., Vonthron S., 2019. Diagnostiquer la précarité alimentaire à une échelle locale, So What? n°10, 4p."
			]
		}
	]
};