module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Risques liés à des contraintes économiques"
		},
		{
			"type": "text",
			"value": "Le coût plus élevé des denrées de bonne qualité nutritionnelle (comme les fruits et légumes frais) ou des produits labellisés constitue un frein à l’achat pour les ménages les moins aisés, particulièrement en période d’inflation. En effet, lorsque les contraintes financières sont fortes, l’alimentation devient souvent la principale variable d’ajustement du budget des ménages : on paye d’abord son loyer et ses factures (charges fixes), puis on fait ses courses alimentaires en fonction du budget restant (reste à vivre). Les ménages ayant un reste à vivre faible et/ou instable (parmi lesquels on trouve beaucoup de familles monoparentales, de travailleurs pauvres, d’étudiants, de personnes en situation administrative irrégulière…) sont ainsi particulièrement vulnérables face au risque de précarité alimentaire."
		},
		{
			"type": "text",
			"value": "## [En savoir plus](#en-savoir-plus)"
		},
		{
			"type": "list",
			"value": [
				"[Laisney, C., (2013), Les différences sociales en matière d’alimentation, Centre d’études et de prospective, n°64.](https://agriculture.gouv.fr/les-differences-sociales-en-matiere-dalimentation-analyse-ndeg64)",
				"[Darmon, N. & Drewnowski, A., (2008), Does social class predict diet quality?, The American Journal of Clinical Nutrition, vol. 87, n°5, p. 1107‑1117.](https://pubmed.ncbi.nlm.nih.gov/18469226/)",
				"[Darmon, N., Ferguson, E. L. & Briend, A., (2002), A Cost Constraint Alone Has Adverse Effects on Food Selection and Nutrient Density: An Analysis of Human Diets by Linear Programming, The Journal of Nutrition, vol. 132, n°12, p. 3764‑3771.](https://pubmed.ncbi.nlm.nih.gov/12468621/)",
				"[Maillot, M., Darmon, N. & Drewnowski, A., (2010), Are the lowest-cost healthful food plans culturally and socially acceptable?, Public Health Nutrition, vol. 13, n°8, p. 1178‑1185.](https://www.cambridge.org/core/journals/public-health-nutrition/article/are-the-lowestcost-healthful-food-plans-culturally-and-socially-acceptable/18E71A5A122177B3E0E6E0BB52EB2691)",
				"[Darmon N., (2021), Nutrition, Santé et pauvreté, Autodiagnostic Accessible, Ressource n°8](https://www.civam.org/wp-content/uploads/2021/02/FICHE-8.pdf)"
			]
		},
		{
			"type": "text",
			"value": "## Présentation des indicateurs utilisés"
		},
		{
			"type": "text",
			"value": "### Médiane du revenu disponible par unité de consommation (€)"
		},
		{
			"type": "text",
			"value": "Echelle concernée : Communes<br>Source de données : Insee-DGFIP-Cnaf-Cnav-CCMSA, Fichier localisé social et fiscal (Filosofi), 2020 (open data)<br>[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/mediane-du-revenu-disponible-par-uc)"
		},
		{
			"type": "text",
			"value": "### 1er quartile du revenu disponible par unité de consommation (€)"
		},
		{
			"type": "text",
			"value": "Echelle concernée : IRIS<br>Source de données : Insee-DGFIP-Cnaf-Cnav-CCMSA, Fichier localisé social et fiscal (Filosofi), 2020 (open data)"
		}
	],
	"verbatim": [
		{
			"type": "text",
			"value": "## Les difficultés économiques en mots"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": ">*« Quand mes alloc’ arrivent sur mon compte, il me reste, allez, on va dire cent euros pour que je  puisse réussir à manger, après, tout le reste qui passe, c’est du découvert »*"
		},
		{
			"type": "text",
			"value": ">*« Moi, ce mois-ci, je suis à moins 200 et je n’ai pas acheté de bouffe ! »*"
		},
		{
			"type": "text",
			"value": ">*« Il y a 5 ans, avec 50 euros, on mettait un caddie plein. Mais maintenant, je mange toujours hard discount, et avec 50 euros, c’est la moitié du caddie. »*"
		}
	]
};