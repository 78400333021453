<template>
  <div>
    <div class="carre-couleur">
      <div class="couleur-gris">Test</div>
      <div class="couleur-gris-20">Test</div>
      <div class="couleur-bleu">Couleur bleu</div>
      <div class="couleur-azur">Couleur azur</div>
      <div class="couleur" style="background-color: var(--couleur-vert-base)">
        Vert base
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-base-80)"
      >
        Vert base 80
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-base-60)"
      >
        Vert base 60
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-base-40)"
      >
        Vert base 40
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-base-20)"
      >
        Vert base 20
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-prairie)"
      >
        Vert prairie
      </div>
      <div class="couleur" style="background-color: var(--couleur-vert-foret)">
        Vert foret
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-foret-80)"
      >
        Vert foret 80
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-foret-60)"
      >
        Vert foret 60
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-foret-40)"
      >
        Vert foret 40
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-vert-foret-20)"
      >
        Vert foret 20
      </div>
      <div class="couleur" style="background-color: var(--couleur-orange)">
        Orange
      </div>
      <div class="couleur" style="background-color: var(--couleur-orange-80)">
        Orange 80
      </div>
      <div class="couleur" style="background-color: var(--couleur-orange-60)">
        Orange 60
      </div>
      <div class="couleur" style="background-color: var(--couleur-orange-40)">
        Orange 40
      </div>
      <div class="couleur" style="background-color: var(--couleur-orange-20)">
        Orange 20
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-orange-fonce)"
      >
        Orange foncé
      </div>
      <div
        class="couleur"
        style="background-color: var(--couleur-orange-clair)"
      >
        Orange clair
      </div>
      <div class="couleur" style="background-color: var(--couleur-jaune)">
        Jaune
      </div>
      <div class="couleur" style="background-color: var(--couleur-jaune-80)">
        Jaune 80
      </div>
      <div class="couleur" style="background-color: var(--couleur-jaune-60)">
        Jaune 60
      </div>
      <div class="couleur" style="background-color: var(--couleur-jaune-40)">
        Jaune 40
      </div>
      <div class="couleur" style="background-color: var(--couleur-jaune-20)">
        Jaune 20
      </div>
      <div class="couleur" style="background-color: var(--couleur-rose)">
        Rose
      </div>
      <div class="couleur" style="background-color: var(--couleur-rose-80)">
        Rose 80
      </div>
      <div class="couleur" style="background-color: var(--couleur-rose-60)">
        Rose 60
      </div>
      <div class="couleur" style="background-color: var(--couleur-rose-40)">
        Rose 40
      </div>
      <div class="couleur" style="background-color: var(--couleur-rose-20)">
        Rose 20
      </div>
      <div class="couleur" style="background-color: var(--couleur-rouge)">
        rouge
      </div>
      <div class="couleur" style="background-color: var(--couleur-rouge-80)">
        rouge-80
      </div>
      <div class="couleur" style="background-color: var(--couleur-rouge-60)">
        rouge-60
      </div>
      <div class="couleur" style="background-color: var(--couleur-rouge-40)">
        rouge-40
      </div>
      <div class="couleur" style="background-color: var(--couleur-rouge-20)">
        rouge-20
      </div>
      <div class="couleur" style="background-color: var(--couleur-corail)">
        corail
      </div>
      <div class="couleur" style="background-color: var(--couleur-corail-80)">
        corail-80
      </div>
      <div class="couleur" style="background-color: var(--couleur-corail-60)">
        corail-60
      </div>
      <div class="couleur" style="background-color: var(--couleur-corail-40)">
        corail-40
      </div>
      <div class="couleur" style="background-color: var(--couleur-corail-20)">
        corail-20
      </div>
      <div class="couleur" style="background-color: var(--couleur-violet)">
        violet
      </div>
      <div class="couleur" style="background-color: var(--couleur-violet-80)">
        violet-80
      </div>
      <div class="couleur" style="background-color: var(--couleur-violet-60)">
        violet-60
      </div>
      <div class="couleur" style="background-color: var(--couleur-violet-40)">
        violet-40
      </div>
      <div class="couleur" style="background-color: var(--couleur-violet-20)">
        violet-20
      </div>
      <div class="couleur" style="background-color: var(--couleur-mauve)">
        mauve
      </div>
      <div class="couleur" style="background-color: var(--couleur-mauve-80)">
        mauve-80
      </div>
      <div class="couleur" style="background-color: var(--couleur-mauve-60)">
        mauve-60
      </div>
      <div class="couleur" style="background-color: var(--couleur-mauve-40)">
        mauve-40
      </div>
      <div class="couleur" style="background-color: var(--couleur-mauve-20)">
        mauve-20
      </div>
      <div class="couleur" style="background-color: var(--couleur-marron)">
        marron
      </div>
      <div class="couleur" style="background-color: var(--couleur-marron-80)">
        marron-80
      </div>
      <div class="couleur" style="background-color: var(--couleur-marron-60)">
        marron-60
      </div>
      <div class="couleur" style="background-color: var(--couleur-marron-40)">
        marron-40
      </div>
      <div class="couleur" style="background-color: var(--couleur-marron-20)">
        marron-20
      </div>
      <div class="couleur" style="background-color: var(--couleur-azur)">
        azur
      </div>
      <div class="couleur" style="background-color: var(--couleur-azur-80)">
        azur-80
      </div>
      <div class="couleur" style="background-color: var(--couleur-azur-60)">
        azur-60
      </div>
      <div class="couleur" style="background-color: var(--couleur-azur-40)">
        azur-40
      </div>
      <div class="couleur" style="background-color: var(--couleur-azur-20)">
        azur-20
      </div>
      <div class="couleur" style="background-color: var(--couleur-bleu)">
        bleu
      </div>
      <div class="couleur" style="background-color: var(--couleur-bleu-80)">
        bleu-80
      </div>
      <div class="couleur" style="background-color: var(--couleur-bleu-60)">
        bleu-60
      </div>
      <div class="couleur" style="background-color: var(--couleur-bleu-40)">
        bleu-40
      </div>
      <div class="couleur" style="background-color: var(--couleur-bleu-20)">
        bleu-20
      </div>
      <div class="couleur" style="background-color: var(--couleur-bleu-fonce)">
        bleu-foncé
      </div>
      <div class="couleur" style="background-color: var(--couleur-azur-fonce)">
        azur-foncé
      </div>

      <div class="couleur" style="background-color: #ff0000"></div>
      <div class="couleur" style="background-color: #8b0000"></div>
    </div>
  </div>
</template>

<style scoped>
.couleur-gris {
  background-color: var(--couleur-gris);
}
.couleur-gris-20 {
  background-color: var(--couleur-gris-20);
}

.couleur-bleu {
  background-color: var(--couleur-bleu);
}

.couleur-azur {
  background-color: var(--couleur-azur);
}
</style>
