<template>
  <div class="modal" v-if="ouvrirModal">
    <div class="modal-content">
      <span class="close" @click="fermerModal">&times;</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalAttention",
  props: {
    ouvrirModal: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    fermerModal() {
      console.log("fermerModal");
      this.$emit("fermerModal");
    },
  },
};
</script>

<style>
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
</style>
