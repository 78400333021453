module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Risques liés à un isolement social"
		},
		{
			"type": "text",
			"value": "L’alimentation, vectrice de partage et de convivialité, comporte une importante dimension sociale. Aussi, l’isolement et la solitude induits par certaines situations ou âges de la vie (tel que la vieillesse) peuvent mettre à mal le caractère hédoniste de l’alimentation et fragiliser l’équilibre des repas. Des ruptures dans les parcours de vie, qu’elles soient familiales (veuvage, séparation conjugale…) ou professionnelles (perte d’emploi) peuvent en particulier décourager la pratique culinaire et constituer un facteur d’appauvrissement alimentaire. Par ailleurs, l’exclusion sociale dont sont victimes certaines personnes (comme les sans-abri ou les personnes en situation administrative irrégulière) ne leur permet pas de bénéficier du soutien extérieur habituellement apporté par le cercle familial ou amical dans des situations difficiles."
		},
		{
			"type": "text",
			"value": "## En savoir plus"
		},
		{
			"type": "list",
			"value": [
				"[Ramel, M. & Boissonnat, H., (2018), Nourrir ou se nourrir. Renouveler le sens que l’on porte à l’acte alimentaire pour renouveler nos pratiques face à la précarité alimentaire, Forum, vol. 153, n°1, p. 53‑61.](https://www.cairn.info/revue-forum-2018-1-page-53.htm?contenu=resume)",
				"[Cardon, P., (2009), « Manger » en vieillissant pose-t-il problème ? Veuvage et transformations de l’alimentation de personnes âgées, Lien social et Politiques, n°62, p. 85‑95.](https://www.erudit.org/fr/revues/lsp/2009-n62-lsp3629/039316ar/)",
				"[Lhuissier, A., (2006), Pauvreté, monoparentalité et alimentation: Une étude de cas dans le nord de la France, Cahiers de Nutrition et de Diététique, vol. 41, n°2, p. 104‑110.](https://pubmed.ncbi.nlm.nih.gov/18469226/)",
				"[Plancade, A., (2014), Vivre dans la rue à Nice : Cuisine et récupération alimentaire, Éditions L’Harmattan, Paris, 150 p.](https://www.sciencedirect.com/science/article/abs/pii/S0007996006706149)",
				"[Barrey, S., Dubuisson-Quellier, S., Gojard, S. & Plessz, M., (2016), Les effets du gouvernement sur les conduites : le rôle des bifurcations des trajectoires de vie dans les changements de conduite de consommation, In : Gouverner les conduites, Les Presses de Sciences Po, p. 399‑448.](https://www.cairn.info/gouverner-les-conduites--9782724619003-page-399.htm)"
			]
		},
		{
			"type": "text",
			"value": "## Présentation des indicateurs utilisés"
		},
		{
			"type": "text",
			"value": "### Taux de chômage des 15-64 ans (%)"
		},
		{
			"type": "text",
			"value": "Echelles concernées : Communes, IRIS<br>Source des données : Souce : Insee, RP 2008-2013-2019 (open data)<br>[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/taux-de-chomage-des-15-64-ans-rp)"
		},
		{
			"type": "text",
			"value": "### Part des ménages dont la famille principale est une famille monoparentale (%)"
		},
		{
			"type": "text",
			"value": "Echelles concernées : Communes, IRIS<br>Source des données : Insee, RP 2008-2013-2019 (open data)<br>[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-menages-dont-la-famille-principale-est-une-famille-monoparentale)"
		},
		{
			"type": "text",
			"value": "### Part des ménages d’une seule personne (%)"
		},
		{
			"type": "text",
			"value": "Echelles concernées : Communes, IRIS<br>Source des données : Insee, RP 2008-2013-2019 (open data)<br>[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-menages-dune-seule-personne)"
		},
		{
			"type": "text",
			"value": "### Taux de jeunes non insérés (ni en emploi, ni scolarisés – NEET) (%)"
		},
		{
			"type": "text",
			"value": "Echelles concernées : Communes, IRIS<br>Source des données : Souce : Insee, RP 2008-2013-2019 (open data)<br>[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-jeunes-non-inseres-ni-en-emploi-ni-scolarises-neet)"
		},
		{
			"type": "text",
			"value": "### Part des 75 ans et plus (%)"
		},
		{
			"type": "text",
			"value": "Echelles concernées : Communes, IRIS<br>Source des données : Insee, RP 2008-2013-2019 (open data)<br>[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-75-ans-et-plus)"
		}
	],
	"verbatim": [
		{
			"type": "text",
			"value": "## La solitude et l’isolement social en mots"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": ">*« Pour le lien social et l’expérience de vie, quand on n’ose pas aller chez les gens parce qu’on peut rien apporter, tout simplement manger et qu’on ne peut pas inviter les gens et rendre la pareille, renvoyer l’ascenseur… Y’a ça aussi dans le lien social. […] Donc la nourriture peut créer du lien social comme ça peut séparer, éviter qu’on accepte d’aller chez des gens »*"
		},
		{
			"type": "text",
			"value": ">*« Si t’es tout seule, toute seule à la maison, moi je sais que je vais prendre du pain, hop une tranche de jambon, une feuille de salade et puis ça y est […] non, c’est pas équilibré mais ça va vite. »*"
		},
		{
			"type": "text",
			"value": ">*« Si j’ai pas le moral, je ne me fais pas à manger. »*"
		},
		{
			"type": "text",
			"value": ">*« Je sais que je me venge sur la nourriture. Quand ça va plus – tous ces problèmes, mon homme au chômage, ma vie qui part en vrille –, je me relève pour manger de la mayo. Ça me calme, ça calme mon angoisse. »*"
		},
		{
			"type": "text",
			"value": ">*« Moi, personnellement, je suis toute seule. Je prends beaucoup de légumes surgelés parce que c’est plus pratique pour moi. Et puis, comme ça, je peux m’en préparer que des petites parts. Alors que si j’achète des légumes frais, ben ce n’est pas à ma portée déjà, puis ils sont chers donc, ben, je vais en manger qu’une fois. »*"
		},
		{
			"type": "text",
			"value": ">*« Je voulais retrouver des relations, recevoir chez moi, trouver une place parmi les autres. »*"
		}
	]
};