import XLSX from "xlsx";

export default {
  convertApiResultToArray(jsonFromApi, id_column_name) {
    var dataSortedById = {};
    for (var indicateur in jsonFromApi) {
      for (var id in jsonFromApi[indicateur]) {
        if (dataSortedById[id] == undefined) {
          dataSortedById[id] = {};
        }
        dataSortedById[id][indicateur] = jsonFromApi[indicateur][id];
      }
    }
    var dataAsArray = [];
    for (id in dataSortedById) {
      var newItem = {};
      newItem[id_column_name] = id;
      newItem = { ...newItem, ...dataSortedById[id] };
      dataAsArray.push(newItem);
    }
    return dataAsArray;
  },
  exportToWorkbook(feuilletDonneesArray, nomFichier) {
    const typeFichier = "ods";
    const workbook = XLSX.utils.book_new();

    feuilletDonneesArray.forEach(
      ({ donneesJson: donneesJson, nomFeuillet: nomFeuillet }, index) => {
        console.log(
          `json to convert to ods for sheet '${nomFeuillet}' =>`,
          donneesJson
        );

        const worksheet = XLSX.utils.json_to_sheet(donneesJson);
        var columns_widths = [];
        for (var colonne in donneesJson[0]) {
          const max_width = donneesJson.reduce(
            (w, r) => Math.max(w, String(r[colonne]).length),
            colonne.length
          );
          columns_widths.push({ wch: max_width });
        }
        worksheet["!cols"] = columns_widths;

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          nomFeuillet || `Sheet${index + 1}`
        );
      }
    );

    XLSX.writeFile(workbook, `${nomFichier}.${typeFichier}`, {
      compression: true,
      bookType: typeFichier,
      cellStyles: true,
    });

    return workbook;
  },
};
