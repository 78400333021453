<template>
  <div id="pageFacteurs">
    <div id="titrePage">
      <h2>
        Choisissez une catégorie de facteurs de risque pour afficher la carte
        correspondante :
      </h2>
    </div>
    <div id="listeCartesFacteurs">
      <div
        v-for="facteur in facteursRisqueListe.filter(
          (facteur) => facteur.lienAncre !== 'synthetique'
        )"
        :key="facteur"
      >
        <carteFacteur
          :nomFacteur="facteur.nomFacteur"
          :ancreFacteur="facteur.lienAncre"
          :codeDepartement="$route.params.codeDepartement"
          :class="facteur.lienAncre"
          :couleurFond="facteur.couleur"
          :imageFacteur="facteur.image"
        ></carteFacteur>
      </div>
      <div id="carte-facteur-synthetique">
        <h4 class="facteur-titre">
          <b>Approche combinée</b><br />
          <router-link
            :to="{
              path: '/indicateurs',
              query: { facteur: 'synthetique' },
            }"
            class="boutonDoc"
            >> En savoir plus</router-link
          >
        </h4>
        <div>
          <inline-svg
            class="logo"
            :src="require('@/assets/icons/synthetique.svg')"
            fill="black"
            height="40"
          />
        </div>
        <div class="boutons">
          <BoutonStandard
            type="encadre"
            couleur="couleur-accent"
            :lienRoute="{
              name: 'Dashboard Territoire',
              params: { codeDepartement: codeDepartement },
              query: { ancreFacteur: 'synthetique' },
            }"
            >Voir la carte</BoutonStandard
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carteFacteur from "@/composants/carteFacteur.vue";
import facteurs_de_risques from "@/assets/donnees/facteurs_de_risques.json";
import liste_departements from "@/assets/donnees/liste_departements.json";
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";

export default {
  name: "pageFacteurs",
  components: { carteFacteur, BoutonStandard },
  data() {
    return {
      facteursRisqueListe: facteurs_de_risques,
      nomDepartement: null,
      codeDepartement: this.$route.params.codeDepartement,
    };
  },
  mounted() {
    this.nomDepartement = liste_departements.find(
      (departement) => departement.code === this.$route.params.codeDepartement
    ).nom;
  },
};
</script>

<style scoped>
#titrePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--dsem2);
  text-align: center;
}

#pageFacteurs {
  display: flex;
  height: calc(100vh - var(--hauteur-barre-navigation));
  padding-top: var(--dsem2);
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 100%
    ),
    url(https://obso-alim.org/wp-content/uploads/2023/02/Fond-gris.jpg) !important;
  flex-direction: column;
  justify-content: flex-start;
}

#listeCartesFacteurs {
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  grid-template-rows: minmax(200px, 1fr) minmax(50px, 180px);
  max-width: 1024px;
  max-height: 420px;
  margin: 0 auto;
  justify-items: stretch;
  align-items: stretch;
  gap: var(--dsem2);
  padding: var(--dsem2);
}

#listeCartesFacteurs div:last-child {
  grid-column-start: 1;
  grid-column-end: 5;
}

.synthetique {
  background-color: var(--couleur-primaire);
  color: var(--couleur-neutre-clair);
}

#carte-facteur-synthetique {
  background-color: #b9e3e3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
</style>
