<template>
  <div id="BoutonRadio">
    <h3 class="titre-petit">{{ titreMenu }}</h3>
    <label
      v-for="radio in listeRadio"
      :key="radio"
      :value="radio"
      class="texte-petit"
    >
      <input
        type="radio"
        :name="nomRadio"
        :value="radio"
        v-model="elementChoisi"
        :checked="radio[clépourDéfaut]"
        @change="
          $emit('update:modelValue', radio);
          console.log(modelValue);
        "
      />
      {{ radio[this.cléAffichée] }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    titreMenu: {
      type: Text,
      default: "Titre du Nouveau Menu",
    },
    listeRadio: {
      type: Object,
    },
    nomRadio: {
      type: Text,
      default: "nomRadio",
    },
    modelValue: {
      type: Text,
      default: "Titre du Nouveau Menu",
    },
    cléAffichée: {
      type: Text,
      default: "nomRadio",
    },
    clépourDéfaut: {
      type: Text,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      SousElementsVisibles: false,
      elementChoisi: "Default",
    };
  },
  watch: {
    modelValue(nouvelleVal) {
      this.elementChoisi = nouvelleVal;
    },
  },
};
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
}

#BoutonRadio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

label {
  display: flex;
  align-items: center;
  gap: var(--dsem);
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: var(--couleur-primaire);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--couleur-primaire);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
