module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Risques liés à un environnement alimentaire appauvri"
		},
		{
			"type": "text",
			"value": "La disponibilité d’une offre alimentaire de qualité dans l’environnement de vie des personnes (à proximité de leur domicile, de leur travail, de l’école des enfants, etc.) peut faciliter leur accès à une alimentation saine et choisie. A l’inverse, des difficultés d’accès physique à des points vente alimentaire correspondant à leur budget, leurs pratiques culturelles ou encore leurs horaires constituent des facteurs de risque de précarité alimentaire. Ces difficultés peuvent être liées à des contraintes de mobilité, des contraintes horaires pour certains lieux d’approvisionnement (marchés de plein vent, commerces ambulants), une offre peu dense et/ou peu diversifiée, trop chère pour une partie de la population, etc. Ainsi, dans des zones rurales peu pourvues en commerces alimentaires, ne pas posséder de véhicule peut constituer un frein à l’approvisionnement alimentaire. Dans les espaces urbains, bien que globalement l’offre soit plus importante et les réseaux de transports en commun plus développés, l’accès à une alimentation de qualité peut rester difficile, notamment pour les habitants ayant peu d’opportunités de sortir de leur quartier."
		},
		{
			"type": "text",
			"value": "## En savoir plus"
		},
		{
			"type": "list",
			"value": [
				"[Vonthron, S., (2021), Offre commerciale alimentaire et pratiques spatiales d’approvisionnement des ménages. Construire une géographie des paysages alimentaires (Thèse de doctorat en géographie), Université Paul Valéry Montpellier 3.](https://theses.fr/2021MON30026)",
				"[Atanasova, P., Kusuma D., Pineda E., Frost G., Sassi F., Miraldo M., (2022), The impact of the consumer and neighbourhood food environment on dietary intake and obesity-related outcomes: A systematic review of causal impact studies. Social Science & Medicine 299: 114879. doi: 10.1016/j.socscimed.2022.114879.](https://pubmed.ncbi.nlm.nih.gov/35290815/)",
				"[Brand, C., Ferrand M., Vandenbroucke P., Delfosse C., Vonthron S., (2022), Se nourrir en territoire difficile. Dans les franges urbaines et rurales de l’hexagone. In Le Déméter 2022. Alimentation : les nouvelles frontières, éd. S. Abis, 157‑164. IRIS éditions.](https://www.researchgate.net/publication/359230618_Se_nourrir_en_territoire_difficile_Dans_les_franges_urbaines_et_rurales_de_l%27hexagone)",
				"[Pitt, E., Gallegos D., Comans T., Cameron C., Thornton L., (2017), Exploring the influence of local food environments on food behaviours: a systematic review of qualitative literature. Public Health Nutrition 20(13): 2393‑2405. doi: 10.1017/S1368980017001069.](https://pubmed.ncbi.nlm.nih.gov/28587691/)"
			]
		},
		{
			"type": "text",
			"value": "## Présentation des indicateurs utilisés"
		},
		{
			"type": "text",
			"value": "### Temps d’accès par la route vers la grande surface la plus proche (secondes)"
		},
		{
			"type": "text",
			"value": "Echelle concernée : Communes<br>Source de données : jeu de données construit à partir de la BPE (Insee), mis à disposition sur data.gouv.fr<br>Open data"
		},
		{
			"type": "text",
			"value": "### Taux de non motorisation des ménages (%)"
		},
		{
			"type": "text",
			"value": "Echelles concernées : Communes<br>Source de données : jeu de données construit à partir de celles de l’indicateur « Part des ménages disposant d'au moins une voiture », Insee, RP 2008-2013-2019<br>Open data"
		},
		{
			"type": "text",
			"value": "### Densité de GMS pour 1000 habitants (magasins)"
		},
		{
			"type": "text",
			"value": "Echelle concernée : IRIS<br>Source de données : Base permanente des équipements (2021) et Recensement de population (2020) - INSEE (open data)"
		},
		{
			"type": "text",
			"value": "### Densité de supérettes et d’épiceries  pour 1000 habitants (magasins)"
		},
		{
			"type": "text",
			"value": "Echelle concernée : IRIS<br>Source de données : Base permanente des équipements (2021) et Recensement de population (2020) - INSEE (open data)"
		},
		{
			"type": "text",
			"value": "### Score de diversité offre alimentaire (dont commerces spécialisés et restaurants)"
		},
		{
			"type": "text",
			"value": "Echelle concernée : IRIS<br>Source de données : Jeu de données construit à partir de la Base permanente des équipements (2021) et Recensement de population (2020) - INSEE (open data)"
		}
	],
	"verbatim": [
		{
			"type": "text",
			"value": "## Les contraintes liées au paysage alimentaire en mots"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": ">*« – T’imagines, M., où elle est, y a un bus le matin, un bus le midi, un bus le soir. Et quand il n’y a pas d’école, y a pas de bus. Parce que c’est le bus scolaire.<br>– Ça, c’est la campagne.<br>– Et ils sont à 7 km du premier magasin, ils vont à pied.<br>– Ils vont à pied ? 7 km ?<br>– Moi, j’ai la bagnole, donc quand j’y vais, ça fait moins loin pour elle que pour moi. Parce qu’elle habite dans le village d’après, mais tu vois, les gens avec leurs caddies sur le bord de la route. Avec leur charrette. »*"
		},
		{
			"type": "text",
			"value": ">*« C’est intéressant de voir que, par exemple, quand t’as trois machins comme ça, déjà comment t’y vas, eh bien il faut y aller en bus. Tu ne peux pas y aller, il faut y aller en voiture, tu calcules ton prix d’essence, tu calcules machin, au total, il vaut mieux aller à côté de chez toi et puis prendre ce qu’il y a… »*"
		},
		{
			"type": "text",
			"value": ">*« Moi je ne peux pas, je suis à pied, je vais mettre mon caddie, et je n’achète jamais tout, parce que je ne peux pas porter. On est bien d’accord, donc ça fait que j’y retourne, pour acheter les restes. »*"
		},
		{
			"type": "text",
			"value": ">*« Les jardins partagés, mais il faut qu’ils soient à proximité parce que ça, c’est souvent en dehors des agglomérations, donc il faut avoir un moyen de transport ou prendre les bus, faut avoir la possibilité. »*"
		}
	]
};