<template>
  <div id="composant-recherche">
    <inline-svg
      class="loupe"
      :src="require('@/assets/icons/loupe.svg')"
      fill="var(--couleur-primaire)"
      width="20"
      height="20"
    />
    <label>
      <input
        type="text"
        id="recherche-barre"
        :class="{ resultatChoisi: departementChoisi !== '' }"
        :placeholder="texteParDefaut"
        v-model="rechercheInput"
      />
    </label>
    <inline-svg
      class="fleche icones"
      :src="
        require('@packages/ui-components/src/assets/icon/navigation/fleche-basse.svg')
      "
      :style="{ transform: ouvrirListe ? 'rotate(180deg)' : 'rotate(0deg)' }"
      fill="var(--couleur-primaire)"
      width="20"
      height="20"
      @click="
        this.rechercheInput = '';
        this.ouvrirListe = !ouvrirListe;
      "
    />
    <ul
      id="recherche-resultat"
      style="position: absolute; z-index: 1"
      v-if="list_geo.length > 0"
    >
      <li
        v-for="resultat in list_geo"
        :key="resultat.id"
        @click="actionClick(resultat)"
      >
        {{ resultat.nom }}
      </li>
    </ul>
  </div>
</template>

<script>
import liste_departements from "@/assets/donnees/liste_departements.json";
import "@packages/ui-components/src/assets/icon/navigation/fleche-basse.svg";

export default {
  name: "barreRechercheDepartements",
  props: {
    nomDepartementDefaut: {
      type: String,
      required: true,
      texteParDefaut: {
        type: String,
        default: "Rechercher un département",
      },
    },
  },
  data() {
    return {
      rechercheInput: this.nomDepartementDefaut,
      list_geo: [],
      departementChoisi: "",
      ouvrirListe: false,
    };
  },
  methods: {
    actionClick(resultat) {
      this.$emit("departementChoisi", resultat);
      console.log(resultat);
      this.rechercheInput = "";
      this.texteParDefaut = resultat.nom;
      this.ouvrirListe = false;
    },
  },
  watch: {
    rechercheInput: function (nouvelleValeur) {
      if (nouvelleValeur.length > 0) {
        this.list_geo = liste_departements.filter(
          (departement) =>
            departement.nom
              .toLowerCase()
              .includes(nouvelleValeur.toLowerCase()) ||
            departement.code
              .toLowerCase()
              .includes(nouvelleValeur.toLowerCase())
        );
      } else {
        this.list_geo = [];
      }
    },
    ouvrirListe: function (nouvelleValeur) {
      if (nouvelleValeur) {
        this.list_geo = liste_departements;
      } else {
        this.list_geo = [];
      }
    },
  },
};
</script>

<style scoped>
#composant-recherche {
  position: relative;
  width: 100%;
}
#recherche-barre {
  width: 100%;
  padding: var(--dsem);
  margin: 0px 0;
  box-sizing: border-box;
  border: 1px solid var(--couleur-gris-80);
  background-color: #ffffff;
  font-size: 16px;
  background-size: 20px;
  padding-left: 40px;
}

#recherche-barre:focus {
  outline: none;
  border: 1px solid var(--couleur-gris);
}
#recherche-resultat {
  list-style-type: none;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid var(--couleur-gris-80);
  box-sizing: border-box;
}

#recherche-resultat > li {
  padding: var(--dsem) var(--dsem2);
  cursor: pointer;
  background-color: #ffffff;
}

#recherche-resultat > li:hover {
  padding: var(--dsem) var(--dsem2);
  cursor: pointer;
  background-color: var(--couleur-gris-40);
}

.resultatChoisi {
  background-color: var(--couleur-gris-80) !important;
  border: 1px solid var(--couleur-gris) !important;
}

.resultatChoisi::placeholder {
  color: var(--couleur-blanc);
}
.loupe {
  position: absolute;
  top: 25%;
  left: 10px;
  fill: var(--couleur-primaire);
}

path {
  fill: var(--couleur-primaire) !important;
}

.fleche {
  position: absolute;
  top: 25%;
  right: 10px;
  fill: var(--couleur-primaire);
}
</style>
