<template>
  <div id="grille">
    <aside class="bordureGauche">
      <slot name="bordureGauche"></slot>
    </aside>
    <main class="contenu">
      <slot name="contenu"> </slot>
    </main>
    <aside class="bordureDroite">
      <slot name="bordureDroite"></slot>
    </aside>
  </div>
</template>

<style scoped>
#grille {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: "bordureGauche contenu bordureDroite";
  gap: var(--dsem2);
  margin: auto;
  width: 100%;
  height: 100%;
}

.bordureGauche {
  grid-area: bordureGauche;
}

.bordureDroite {
  grid-area: bordureDroite;
}

.contenu {
  grid-area: contenu;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
</style>
