import axios from "axios";
import api from "@packages/utils/BasicAPIReader.js";

api.setNewApiUrl("https://lebasic.nohost.me/api/");

async function getGeoJsonFromBasic(
  code_territoire,
  niveau_detail_geographique
) {
  var url = `/territoire/GeoJSON/${code_territoire}?niveau_detail_geographique=${niveau_detail_geographique}`;
  var result = await api.serverCall(url);
  return result;
}

var GeoJson_API_URL =
  "https://cdn.jsdelivr.net/gh/mathieulebasic/france-geojson";

var echelle_geographique_disponible = {
  departement: "departements",
  parc_naturel: "pn",
};

var niveau_detail_disponible = {
  epci: "epci",
  iris: "iris",
  commune: "villes",
};

export default {
  getServerErrors() {
    return Servers_Errors;
  },
  async getGeoJson(
    code_territoire,
    echelle_geographique_territoire,
    niveau_detail_geographique,
    force_api_usage = false
  ) {
    if (force_api_usage || ["epci", "qpv"].includes(niveau_detail_geographique)) {
      var lebasicapiresult = await getGeoJsonFromBasic(
        code_territoire,
        niveau_detail_geographique
      );
      console.log("geojson basic api response", lebasicapiresult);
      return lebasicapiresult;
    }
    if (echelle_geographique_disponible[echelle_geographique_territoire]) {
      echelle_geographique_territoire =
        echelle_geographique_disponible[echelle_geographique_territoire];
    } else {
      /* TODO: c'est un cas où utiliser Typescript pourrait permettre justement de se débarasser de ces gestions d'erreur*/
      throw new Error(
        `Impossible de récupérer les geojson d'un territoire à l'échelle geographique ${echelle_geographique_territoire}. Disponible : ${Object.keys(
          echelle_geographique_disponible
        )}`
      );
    }

    if (niveau_detail_disponible[niveau_detail_geographique]) {
      niveau_detail_geographique =
        niveau_detail_disponible[niveau_detail_geographique];
    } else {
      throw new Error(
        `Impossible de récupérer les geojson d'un territoire au niveau de détail ${niveau_detail_geographique}. Disponible : ${Object.keys(
          niveau_detail_disponible
        )}`
      );
    }

    const urlGeojson = `${GeoJson_API_URL}/${echelle_geographique_territoire}_${niveau_detail_geographique}/${code_territoire}.geojson`;
    var response = await axios.get(urlGeojson);
    return response.data;
  },
};
