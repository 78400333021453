<template>
  <div id="bouton" :class="definirStyle">
    <router-link v-if="typeBouton == 'bouton'" :to="lienRoute">
      <slot></slot>
    </router-link>
    <router-link
      v-if="typeBouton == 'boutonBlank'"
      :to="lienRoute"
      target="_blank"
    >
      <slot></slot>
    </router-link>
    <input v-if="typeBouton == 'input'" type="submit" :value="valeurInput" />
    <slot v-if="typeBouton == 'action'"></slot>
    <a v-if="typeBouton == 'lien'" :href="href">
      <slot></slot>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    href: {
      required: false,
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "relief", //relief, encadre, plat
    },
    couleur: {
      type: String,
      default: "couleur-secondaire", // couleur-primaire, couleur-secondaire, couleur-accent
    },
    taille: {
      type: String,
      default: "titre-petit", // texte-petit, texte-moyen, titre-petit, titre-moyen, titre-grand
    },
    typeBouton: {
      type: String,
      default: "bouton", // lien, input, action
    },
    valeurInput: {
      type: String,
      default: "Envoyer",
    },
    lienRoute: {
      type: String,
      default: "/",
    },
    outline: Boolean,
    round: Boolean,
    square: Boolean,
  },
  computed: {
    definirStyle() {
      const classeCouleur =
        this.couleur === "couleur-primaire"
          ? "theme-primaire"
          : this.couleur === "couleur-secondaire"
          ? "theme-secondaire"
          : "theme-accent";
      const classeType =
        this.type === "encadre"
          ? "theme-encadre"
          : this.type === "relief"
          ? "theme-relief"
          : "theme-plat";
      return classeCouleur + " " + classeType + " " + this.taille;
    },
  },
};
</script>

<style scoped>
#bouton {
  cursor: pointer;
  align-items: center;
  width: fit-content;
  justify-content: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: var(--couleur-fond-base);
  color: var(--couleur-texte-base) !important;
  border: 1px solid var(--couleur-border-base);
}

#bouton:hover {
  background-color: var(--couleur-fond-active);
  color: var(--couleur-texte-active) !important;
}

.theme-primaire {
  --couleur-choisie: var(--couleur-primaire);
  --couleur-choisie-sombre: var(--couleur-primaire-sombre);
}

.theme-secondaire {
  --couleur-choisie: var(--couleur-secondaire);
  --couleur-choisie-sombre: var(--couleur-secondaire-sombre);
}

.theme-accent {
  --couleur-choisie: var(--couleur-accent);
  --couleur-choisie-sombre: var(--couleur-accent-sombre);
}

.theme-encadre {
  --couleur-fond-base: var(--couleur-blanc);
  --couleur-texte-base: var(--couleur-choisie);
  --couleur-fond-active: var(--couleur-choisie);
  --couleur-texte-active: var(--couleur-blanc);
  --couleur-border-base: var(--couleur-choisie);
}

.theme-relief {
  --couleur-fond-base: var(--couleur-choisie);
  --couleur-texte-base: var(--couleur-blanc);
  --couleur-fond-active: var(--couleur-choisie-sombre);
  --couleur-texte-active: var(--couleur-blanc);
}

.theme-plat {
  --couleur-fond-base: var(--couleur-blanc);
  --couleur-texte-base: var(--couleur-choisie);
  --couleur-fond-active: var(--couleur-blanc);
  --couleur-texte-active: var(--couleur-choisie-sombre);
  --couleur-border-base: var(--couleur-blanc);
}
</style>
