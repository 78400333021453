<template>
  <div id="recherche">
    <div class="">
      <h1>
        <b> {{ titre }} </b>
      </h1>
      <h2 v-if="sousTitre">{{ sousTitre }}</h2>
    </div>
    <div class="contenu">
      <slot name="barre-recherche"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccueilRecherche",
  props: {
    titre: {
      type: String,
      default: "Titre de la Page",
    },
    sousTitre: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
#recherche {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: var(--dsem2);
  padding: var(--dsem2);
}

.contenu {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
y
