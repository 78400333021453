<template>
  <div style="height: 80%; width: auto; margin: auto">
    <h2 style="text-align: center; margin-bottom: 20px; font-weight: bold">
      <b>Quatre catégories de facteurs de risque de précarité alimentaire</b>
    </h2>
    <svg max-width="70%" height="90%" viewBox="0 12 80 55" class="donut">
      <text
        font-family="Barlow Condensed"
        x="40"
        y="5"
        font-size="3.5"
        text-anchor="middle"
        dominant-baseline="middle"
      >
        Contraintes économiques
      </text>
      <circle
        class="donut-ring"
        cx="40"
        cy="40"
        r="15.91549430918954"
        fill="transparent"
        stroke="#d2d3d4"
        stroke-width="15"
      ></circle>
      <router-link
        :to="{ path: 'indicateurs', query: { facteur: 'socio_eco' } }"
        class="circle donut-segment"
      >
        <circle
          class="donut-segment"
          cx="40"
          cy="40"
          r="15.91549430918954"
          fill="transparent"
          stroke="#006666"
          stroke-width="15"
          stroke-dasharray="20 80"
          stroke-dashoffset="5"
        ></circle>
        <image
          xlink:href="amis.svg"
          width="5"
          height="5"
          x="56"
          y="43"
          fill="white"
          dominant-baseline="middle"
        />
        <text
          font-family="Barlow Condensed"
          x="68"
          y="45"
          font-size="2.5"
          text-anchor="middle"
          dominant-baseline="middle"
        >
          <tspan>Isolement</tspan>
          <tspan x="68" dy="2">social</tspan>
        </text>
      </router-link>
      <router-link
        :to="{ path: 'indicateurs', query: { facteur: 'monetaire' } }"
        class="circle donut-segment"
      >
        <circle
          class="donut-segment"
          cx="40"
          cy="40"
          r="15.91549430918954"
          fill="transparent"
          stroke="#00A8A8"
          stroke-width="15"
          stroke-dasharray="40 60"
          stroke-dashoffset="45"
        ></circle>
        <image
          xlink:href="pieces.svg"
          width="5"
          height="5"
          x="37.5"
          y="18"
          fill="black"
          stroke="black"
          color="black"
          dominant-baseline="middle"
        />
        <text
          font-family="Barlow Condensed"
          x="40"
          y="15"
          font-size="2.5"
          text-anchor="middle"
          dominant-baseline="middle"
        >
          Contraintes économiques
        </text>
      </router-link>

      <router-link
        :to="{ path: 'indicateurs', query: { facteur: 'litteracie' } }"
        class="circle donut-segment"
      >
        <circle
          class="donut-segment"
          cx="40"
          cy="40"
          r="15.91549430918954"
          fill="transparent"
          stroke="#F9B000"
          stroke-width="15"
          stroke-dasharray="20 80"
          stroke-dashoffset="65"
        ></circle>
        <image
          xlink:href="informations.svg"
          width="5"
          height="5"
          x="19"
          y="43"
          fill="white"
          style="
            fill: white;
            stroke: white;
            color: white;
            filter: hue-rotate(90deg);
          "
          dominant-baseline="middle"
        />
        <text
          font-family="Barlow Condensed"
          x="11"
          y="45"
          font-size="2.5"
          text-anchor="middle"
          dominant-baseline="middle"
        >
          <tspan>Information</tspan>
          <tspan x="11" dy="2.5">alimentaire</tspan>
          <tspan x="11" dy="2.5">insuffisante</tspan>
        </text>
      </router-link>
      <router-link
        :to="{ path: 'indicateurs', query: { facteur: 'mobilite' } }"
        class="circle donut-segment"
      >
        <circle
          class="mobilite donut-segment"
          cx="40"
          cy="40"
          r="15.91549430918954"
          fill="transparent"
          stroke="#B58619"
          stroke-width="15"
          stroke-dasharray="20 80"
          stroke-dashoffset="85"
        ></circle>
        <image
          xlink:href="magasin.svg"
          width="5"
          height="5"
          x="37.5"
          y="57"
          fill="white"
          style="
            fill: white;
            stroke: white;
            color: white;
            filter: hue-rotate(90deg);
          "
          dominant-baseline="middle"
        />
        <text
          font-family="Barlow Condensed"
          x="40"
          y="65.5"
          font-size="2.5"
          text-anchor="middle"
          dominant-baseline="middle"
        >
          Environnement alimentaire appauvri
        </text>
      </router-link>
      <circle
        class="donut-hole"
        cx="40"
        cy="40"
        r="15.91549430918954"
        fill="#EFEBE5"
      ></circle>
    </svg>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed");
/* CSS styles for the circles */
.circle {
  stroke-width: 2px;
  cursor: pointer;
}

.donut-hole {
  z-index: 100;
}
.donut-segment:hover {
  transition: stroke-dashoffset 0.3s;
}
.donut-segment:hover {
  transform: scale(1.02);
  transition: all 0.3s;
  filter: brightness(80%);
}
.donut-segment {
  transition: all 1s;
  transform-origin: 50% 50%;
}
/* CSS to change the color */
/* Replace 'path' with the appropriate selector for the elements you want to colorize */
</style>
