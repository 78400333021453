<template>
  <div id="app">
    <h1>Page Iris Test</h1>
    <fieldset>
      <legend>Select a maintenance drone:</legend>
      <div v-for="indicateur in indicateurs" :key="indicateur">
        <input
          type="radio"
          :id="indicateur"
          name="drone"
          :value="indicateur"
          v-model="indicateurChoisi"
        />
        <label :for="indicateur">{{ indicateur }}</label>
      </div>
      <pre>{{ indicateurChoisi }}</pre>
    </fieldset>

    <fondChloropeth
      v-if="donnees"
      :geojson="geojson"
      :onEachFeatureFunction="onEachFeatureFunction"
      :choisirCouleur="choisirCouleur"
      :couleursPalette="couleursPalette"
      :montrerLegende="false"
      :key="indicateurChoisi"
      @zoomUpdate="console.log('zoomUpdate F', zoom)"
    />
  </div>
</template>

<script>
import fondChloropeth from "@packages/ui-components/src/composants/cartographie/fondChloropeth.vue";
import geojson_api from "@packages/utils/GeoJsonReader.js";
import { getDonneesObsalimIris } from "@/utils/fonction_api.js";

export default {
  components: {
    fondChloropeth,
  },
  data() {
    return {
      geojson: null,
      codeDepartement: "34",
      cleCarte: "1",
      donnees: null,
      couleursPalette: [
        { couleur: "#FFF0F3", label: "Très bas" },
        { couleur: "#FFB3C1", label: "Bas" },
        { couleur: "#FF758F", label: "Moyen" },
        { couleur: "#C9184A", label: "Elevé" },
        { couleur: "#800F2F", label: "Très élevé" },
        { couleur: "#C0C0C0", label: "Secret statistique" },
      ],
      indicateurs: null,
      indicateurChoisi: null,

      carteChargement: false,
    };
  },
  methods: {
    choisirCouleur(feature) {
      var codeIrisCarte = feature.properties.code_iris;
      var codeIrisDonnées = "iris" + codeIrisCarte;
      if (codeIrisCarte.endsWith("0000")) {
        codeIrisDonnées = "com" + codeIrisCarte.substr(0, 5);
      }
      if (this.indicateurChoisi in this.donnees.resultats) {
        if (codeIrisDonnées in this.donnees.resultats[this.indicateurChoisi]) {
          return "#ff0000";
        } else {
          console.log(`code iris ${codeIrisDonnées} pas trouvé :-(:-(:-(:-(`);
          return "#00ff00";
        }
      }
      console.log(`
      indicateur ${this.indicateurChoisi} pas trouvé :-(:-(:-(:-(`);
      return "#0000ff";
    },
    onEachFeatureFunction(feature, layer) {
      layer.on({
        mouseover: this.highlightFeature,
        mouseout: this.resetHighlight,
        click: this.zoomToFeature,
      });
      layer.bindTooltip(feature.properties.code_iris);
    },
  },
  async mounted() {
    await geojson_api
      .getGeoJson(this.codeDepartement, "departement", "iris")
      .then((geojson) => {
        this.geojson = geojson;
      });
    this.donnees = await getDonneesObsalimIris(
      `dep${this.codeDepartement}`,
      "iris"
    );
    console.log("donnees", this.donnees);
    this.indicateurs = Object.keys(this.donnees.resultats);
    this.indicateurChoisi = this.indicateurs[0];
  },
};
</script>

<style scoped>
#app {
  width: 1000px;
  height: 1000px;
  border: 1px solid black;
}
</style>
