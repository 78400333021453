<template>
  <!-- Main navigation container -->
  <nav class="navigation sticky">
    <div class="mobileNavigation">
      <a :href="lienAccueil" class="logo">
        <img v-for="image in imagesLogo" :key="image" :src="image" alt="logo" />
        <h1 class="titreNav" v-if="titreHeader">{{ titreHeader }}</h1>
      </a>
      <button
        class="hamburger"
        type="button"
        data-te-target="#navElements"
        aria-controls="navElements"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="ouvreMenu"
      >
        <!-- Hamburger icon -->
        <span class="hamburger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-7 w-7"
          >
            <path
              fill-rule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
    </div>
    <div class="navElements" :class="{ menuvisible: menuVisible }">
      <input
        v-if="barreVisible"
        type="search"
        class="barrederecherche"
        placeholder="Cherchez un territoire"
        aria-label="Search"
        aria-describedby="button-addon3"
      />
      <div id="menu">
        <router-link
          v-for="element in ElementsMenu"
          v-bind:key="element.chemin"
          :to="element.chemin"
          class="menuelements"
          >{{ element.libelle }}</router-link
        >
      </div>
      <slot name="boutonAdditionnel"></slot>
    </div>
  </nav>
</template>

<script>
export default {
  name: "EnTete",
  props: {
    barreVisible: {
      type: Boolean,
      default: false,
    },
    ElementsMenu: {
      type: Object,
    },
    imageLogoLink: {
      type: String,
      default: "logo.svg",
    },
    lienAccueil: {
      type: String,
      default: "/",
    },
    titreHeader: {
      type: String,
      default: "",
    },
    imagesLogo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuVisible: false,
    };
  },
  methods: {
    ouvreMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>

<style scoped>
.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--couleur-primaire);
  justify-content: space-between;
  padding: 0px;
  z-index: 1000;
}

.mobileNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-left: var(--dsem2);
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--dsem);
}
.logo img {
  max-height: 100%;
  max-width: 200px;
  padding: var(--dsem) 0;
}
.hamburger {
  display: flex;
  height: 16px;
}

.navElements {
  display: none;
  align-items: stretch;
}

.menuvisible {
  display: flex;
  flex-direction: column;
}
.menuvisible #menu {
  display: flex;
  flex-direction: column;
}

.titreNav {
  display: none;
}

@media screen and (min-width: 1024px) {
  .logo {
    height: 100%;
  }

  .logo img {
    max-width: 300px;
  }

  .titreNav {
    display: flex;
    color: white;
  }
  .navigation {
    flex-direction: row;
    justify-content: space-between;
    height: var(--hauteur-barre-navigation);
    align-items: stretch;
  }
  .navElements {
    display: flex;
  }

  .hamburger {
    display: none;
  }

  #menu {
    display: flex;
    padding: 0 var(--dsem2);
  }

  #menu a {
    align-items: center;
  }

  .menuelements {
    display: inline-flex;
  }

  .menuelements {
    padding: 15px var(--dsem2);
    display: flex;
    align-items: center;
  }
  a.router-link-exact-active {
    height: 100%;
    align-content: center;
  }

  .barrederecherche {
    opacity: 0.8;
    border-radius: 0px;
    padding: 8px 16px;
    margin-left: 10px;
    align-self: center;
  }
  .barrederecherche:focus {
    outline: none !important;
    opacity: 1;
    border: 0px;
  }

  .sticky {
    position: sticky;
    top: 0;
  }
  a.router-link-exact-active {
    background-color: var(--couleur-primaire-clair);
    height: 100%;
    align-content: center;
  }

  .titreNav {
    color: white;
    font-size: 24px;
  }
}
</style>
