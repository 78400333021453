import html2canvas from "html2canvas";
import saveAs from "file-saver";

export default async function prendreScreenshot(ref) {
  console.log("Taking screenshot...");
  console.log(ref);
  const options = {
    allowTaint: false,
    useCORS: true,
  };
  html2canvas(document.querySelector(ref), options).then((canvas) => {
    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL();

    // Trigger download using FileSaver.js
    saveAs(dataURL, "carte-vulnerabilite-alimentaire.png");
  });
}
