<template>
  <grilleStandard>
    <template v-slot:contenu>
      <div v-if="archieData">
        <h1 class="couleur-primaire-sombre">
          Présentation des facteurs de risque
        </h1>
        <div id="indicateurs-list-container">
          <div class="indicateurs-list">
            <button
              v-for="facteur in facteursRisqueListe"
              :key="facteur.nomFacteur"
              @click="changerIndicateurs(facteur.lienAncre)"
              :class="{ choisi: changerClass == facteur.lienAncre }"
              :style="{
                backgroundColor:
                  changerClass == facteur.lienAncre
                    ? facteur.couleur
                    : 'var(--couleur-secondaire-clair)',
              }"
            >
              {{ facteur.nomFacteur }}
            </button>
          </div>
        </div>
        <div class="contenu">
          <div class="descriptionIndicateurs">
            <template
              v-for="(contenu, index) in archieData.content"
              :key="index"
            >
              <vue-showdown
                :class="['custom', facteursRisque.lienAncre]"
                v-if="contenu.type == 'text'"
                :markdown="contenu.value"
                flavor="github"
              ></vue-showdown>
              <vue-showdown
                v-default-classes
                v-if="contenu.type == 'list'"
                class="listes custom"
                :markdown="concateneList(contenu.value)"
              ></vue-showdown>
              <p v-if="['text', 'list'].includes(contenu.type)"></p>

              <vue-showdown
                v-if="contenu.type == 'Source'"
                :markdown="contenu.value"
              ></vue-showdown>
              <span class="tag is-info is-light" v-if="contenu.type == 'Tag'">
                <vue-showdown :markdown="contenu.value"></vue-showdown>
              </span>

              <article class="message is-link" v-if="contenu.type == 'Link'">
                <div class="message-body">
                  <vue-showdown :markdown="contenu.value"></vue-showdown>
                </div>
              </article>
            </template>
          </div>
          <div
            class="verbatim custom"
            v-if="verbatims"
            :style="{
              'background-color': facteursRisque.couleurClaire,
            }"
          >
            <template v-for="(contenu, index) in verbatims" :key="index">
              <vue-showdown :markdown="contenu.value"></vue-showdown>
            </template>
          </div>
        </div>
      </div>
    </template>
  </grilleStandard>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import facteurs_de_risques from "@/assets/donnees/facteurs_de_risques.json";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";

import "archieml";
export default {
  name: "IndicateursVIew",
  components: { VueShowdown, grilleStandard },
  data() {
    return {
      archieData: null,
      indicateurChoisi: "monetaire",
      facteursRisqueListe: facteurs_de_risques,
      verbatims: undefined,
    };
  },
  mounted() {
    this.lireAncre();
    this.logArchieMlContenu();
    this.facteurs = this.facteursRisqueListe;
  },
  methods: {
    logArchieMlContenu() {
      console.log("=> Log contenu");
      const archiemlFichier = this.facteursRisqueListe.find(
        (facteur) => facteur.lienAncre == this.indicateurChoisi
      ).fichierArchieMl;
      const archieData = require(`@/assets/description_indicateurs/${archiemlFichier}`);
      this.archieData = archieData;
      console.log(this.archieData);
      this.verbatims = this.archieData.verbatim;
      console.log(this.verbatims);
      return this.archieData;
    },

    lireAncre() {
      this.indicateurChoisi = this.$route.query.facteur;
      console.log(this.indicateurChoisi);
    },

    concateneList(list) {
      var text = "";
      for (let i = 0; i < list.length; i++) {
        text += "* " + list[i] + "\n";
      }
      console.log(text);

      return text;
    },
    changerIndicateurs(indicateur) {
      this.indicateurChoisi = indicateur;
    },
  },
  computed: {
    changerClass() {
      console.log(this.indicateurChoisi);
      return this.indicateurChoisi;
    },
    facteursRisque() {
      return this.facteursRisqueListe.filter(
        (facteur) => facteur.lienAncre == this.indicateurChoisi
      )[0];
    },
  },
  watch: {
    indicateurChoisi: ["logArchieMlContenu"],
  },
};
</script>

<style scoped>
.container {
  display: block;
  margin: auto;
}
.container ul {
  list-style-type: decimal;
}

#indicateurs-list-container {
  display: flex;
  align-items: stretch;
}

.contenu {
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: var(--dsem4);
}

.descriptionIndicateurs {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 2px;
}

.verbatim {
  display: flex;
  flex-direction: column;
  gap: var(--dsem4);
  flex-grow: 1;
  min-width: 50%;
  border-radius: var(--dsem4);
  padding: var(--dsem4) var(--dsem2);
  height: fit-content;
  text-align: center;
}

blockquote {
  background-color: var(--couleur-secondaire-clair);
}

.indicateurs-list {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  align-items: space-between;
  padding: var(--dsem4);
  padding-top: 0;
  justify-content: center; /* center flex items vertically */
}

.indicateurs-list button {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0px;
  flex-direction: column;
  border: solid #f7f7f7 1px;
  background-color: var(--couleur-secondaire-clair);
  padding: var(--dsem2) var(--dsem);
  cursor: pointer;
  letter-spacing: 0.3px;
  align-items: center;
  justify-content: center;
}
.indicateurs-list button:hover {
  background-color: #f7f7f7;
  border: solid 1px #f7f7f7;
  color: #7a7291;
}
.indicateurs-list button.choisi {
  /*background-color: var(--couleur-secondaire-sombre);*/
  font-weight: bold;
  color: var(--couleur-neutre-clair);
  letter-spacing: normal;
}

.listes :deep(ul) {
  list-style: disc inside !important;
}

h1 {
  text-align: left;
  padding: var(--dsem4) 0;
}
.custom :deep(h2) {
  font-weight: bold;
  margin-top: var(--dsem) !important;
}
.custom :deep(h3) {
  font-weight: bold;
  margin-top: var(--dsem) !important;
}
.custom :deep(p) {
  line-height: 20px;
}

.custom :deep(ul li) {
  line-height: 20px;
}
</style>

<style>
.custom h2 {
  font-weight: bold;
}
.custom h3 {
  font-weight: bold;
}
.custom a {
  color: var(--couleur-primaire);
}

.monetaire h2 {
  color: #00a8a8;
}
.socio_eco h2 {
  color: #006666;
}

.mobilite h2 {
  color: #f9b000;
}

.litteracie h2 {
  color: #b58619;
}

.synthetique h2 {
  color: #b9e3e3;
}

.monetaire h3 {
  color: #00a8a880;
}
.socio_eco h3 {
  color: #00666680;
}

.mobilite h3 {
  color: #f9b00080;
}

.litteracie h3 {
  color: #b5861980;
}

.synthetique h3 {
  color: #b9e3e380;
}

h2 {
  line-height: 30px !important;
}
</style>
