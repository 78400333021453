<template>
  <article
    :class="{ montreBoutons: true, contenant: true }"
    :style="{ 'background-color': couleurFond }"
  >
    <div id="carteFacteur">
      <h4 class="facteur-titre" style="min-height: 80px">
        <b>{{ nomFacteur }}</b
        ><br />
        <router-link
          :to="{
            path: '/indicateurs',
            query: { facteur: ancreFacteur },
          }"
          class="boutonDoc"
          >> En savoir plus</router-link
        >
      </h4>
      <div>
        <inline-svg
          class="logo"
          :src="require('@/assets/icons/' + imageFacteur)"
          fill="white"
          width="40"
        />
      </div>
      <div class="boutons" v-if="codeDepartement">
        <BoutonStandardVue
          type="encadre"
          couleur="couleur-accent"
          :lienRoute="{
            name: 'Dashboard Territoire',
            params: { codeDepartement: codeDepartement },
            query: { ancreFacteur: ancreFacteur },
          }"
          >Voir la carte</BoutonStandardVue
        >
      </div>
    </div>
  </article>
</template>

<script>
import BoutonStandardVue from "@packages/ui-components/src/composants/BoutonStandard.vue";

export default {
  name: "carteFacteur",
  components: {
    BoutonStandardVue,
  },
  props: {
    nomFacteur: String,
    ancreFacteur: String,
    imageFacteur: String,
    code: String,
    couleurFond: {
      type: String,
      default: "white",
    },
    codeDepartement: String,
  },
  data() {
    return {
      montreBoutons: false,
    };
  },
  methods: {
    montreBoutonsSurvol() {
      this.montreBoutons = true;
    },
    cacheBoutonsSurvol() {
      this.montreBoutons = false;
    },
  },
};
</script>

<style scoped>
.contenant {
  height: 100%;
  background-color: var(--couleur-neutre-clair);
}

#carteFacteur {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: var(--dsem);
  padding: var(--dsem2);
  text-align: center;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

#carteFacteur h4 {
  flex-grow: 1;
  color: var(--couleur-neutre-clair);
}
.boutons {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.montreBoutons .boutons {
  opacity: 1;
}

.boutonDoc {
  margin-top: var(--dsem);
}

.boutonDoc:hover {
  margin-top: var(--dsem);
  color: black;
}
</style>
