import api from "@packages/utils/BasicAPIReader.js";

api.setNewApiUrl("https://lebasic.nohost.me/api/");

async function chercheTerritoires(text, echelle_geographique) {
  var url = `/parcel/GeoData/${text}?type_territoire=${echelle_geographique}&pays=france`;
  var result = await api.serverCall(url);
  return result;
}

async function chercheDonneesObsoalim(
  facteurRisque,
  code_departement,
  echelle_geographique = "commune"
) {
  var point_api = "";
  switch (echelle_geographique) {
    case "commune":
      point_api = "/obsoalim_communes";
      code_departement = "dep" + code_departement; // TODO: A CORRIGER
      break;
    case "iris":
      point_api = "/obsoalim_iris";
      break;
    // TODO: A CORRIGER
    case "commune_old":
      point_api = "/obsoalim";
      break;
  }
  var url = `${point_api}/${facteurRisque}/${code_departement}`;
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesBasicTerritoires(
  code_territoire,
  annee = null,
  niveau_detail_geographique = null
) {
  var url = `/basic/${code_territoire}`;
  if (annee && niveau_detail_geographique) {
    url += `?annee=${annee}&niveau_detail_geographique=${niveau_detail_geographique}`;
  }
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesFilosofiTerritoires(
  code_territoire,
  annee = null,
  niveau_detail_geographique = null
) {
  var url = `/filosofi/${code_territoire}`;
  if (niveau_detail_geographique || annee) {
    url += "?";
  }
  if (niveau_detail_geographique) {
    url += `niveau_detail_geographique=${niveau_detail_geographique}&`;
  }
  if (annee) {
    url += `annee=${annee}&`;
  }
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesRGATerritoires(
  code_territoire,
  annee,
  type_dimension_rga
) {
  var url = `/RGA/${type_dimension_rga}/${code_territoire}?annee=${annee}`;
  var result = await api.serverCall(url);
  return result;
}

export async function getDonneesObsalimIris(
  code_territoire,
  niveau_detail_geographique
) {
  var url = `/obsoalim-iris/${code_territoire}`;
  if (niveau_detail_geographique) {
    url += `?niveau_detail_geographique=${niveau_detail_geographique}&`;
  }
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesSolinum(code_territoire) {
  var url = `/solinum/${code_territoire}`;
  var result = await api.serverCall(url);
  return result;
}

async function getLibellesTerritoires(
  code_territoire,
  niveau_detail_geographique
) {
  var url = `/territoire/libelle/${code_territoire}?niveau_detail_geographique=${niveau_detail_geographique}`;
  var result = await api.serverCall(url);
  return result;
}

export default {
  getDonneesBasicTerritoires,
  getLibellesTerritoires,
  getDonneesFilosofiTerritoires,
  getDonneesRGATerritoires,
  getDonneesSolinum,
  chercheDonneesObsoalim,
  chercheTerritoires,
};
