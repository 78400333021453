import { createRouter, createWebHistory } from "vue-router";
import dashboardTerritoires from "@/pages/dashboardTerritoires.vue";
import dashboardTerritoiresIris from "@/pages/dashboardTerritoiresIris.vue";
import pageTableauDonnees from "@/pages/pageTableauDonnees.vue";
import pageAccueil from "@/pages/pageAccueil.vue";
import pageApropos from "@/pages/pageApropos.vue";
import pageContact from "@/pages/pageContact.vue";
import pageFacteurs from "@/pages/pageFacteurs.vue";
import pageIndicateurs from "@/pages/pageIndicateurs.vue";
import pageMethodologie from "@/pages/pageMethodologie.vue";
import pageIrisTest from "@/pages/pageIrisTest.vue";
import pageIrisTest2 from "@/pages/pageIrisTest2.vue";
import pageCouleur from "@/pages/pageCouleur.vue";
import pageCartographie from "@/pages/pageCartographie.vue";
const routes = [
  {
    path: "/",
    name: "Accueil",
    component: pageAccueil,
  },
  {
    path: "/territoire/:codeDepartement",
    name: "Dashboard Territoire",
    component: dashboardTerritoires,
  },
  {
    path: "/territoire/iris/:codeDepartement",
    name: "Dashboard Territoire Iris",
    component: dashboardTerritoiresIris,
  },
  {
    path: "/tableau",
    name: "Tableau Données",
    component: pageTableauDonnees,
  },
  {
    path: "/facteur/:codeDepartement",
    name: "Page Facteurs",
    component: pageFacteurs,
  },
  {
    path: "/a-propos",
    name: "A propos",
    component: pageApropos,
  },
  {
    path: "/indicateurs",
    name: "Page Indicateurs",
    component: pageIndicateurs,
  },
  {
    path: "/contact",
    name: "Page Contact",
    component: pageContact,
  },
  {
    path: "/methode-de-calcul",
    name: "Méthode de calcul",
    component: pageMethodologie,
  },
  {
    path: "/iris-test",
    name: "Iris Test",
    component: pageIrisTest,
  },
  {
    path: "/iris-test2",
    name: "Iris Test 2",
    component: pageIrisTest2,
  },
  {
    path: "/couleur",
    name: "Page Couleur",
    component: pageCouleur,
  },
  {
    path: "/tableau",
    name: "tableau",
    component: pageTableauDonnees,
  },
  {
    path: "/cartographie",
    name: "cartographie",
    component: pageCartographie,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  // Log the page view to Matomo
  console.log("rooter to", to.fullPath);
  window._paq.push(["setCustomUrl", to.fullPath]);
  window._paq.push(["trackPageView"]);
});

export default router;
