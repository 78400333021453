<template>
  <div id="dashboard">
    <div id="dashboardContenu">
      <div
        :class="{
          'titre-et-carte': true,
        }"
      >
        <div>
          <h2 v-if="departement">
            <b>
              {{ menu.facteurRisque.nomCarte }} dans les communes
              {{ this.departement.libelle_genitif }}</b
            >
          </h2>
          <h3 v-if="indicateurChoisi">
            Indicateur considéré : {{ indicateurChoisi.nomIndicateur }}
          </h3>
          <h3 v-if="menu.choixComparaison">
            Echelle de référence pour la comparaison :
            {{ territoireComparaison }}
            <router-link to="/methode-de-calcul" class="lien">
              (en savoir plus sur la méthode de calcul)
            </router-link>
          </h3>
        </div>
        <div id="carteLégendée">
          <div id="carte">
            <LoadingWrapper
              :data_loaded="
                donneesCartes != null &&
                indicateurChoisi != null &&
                carte.carte_geojson != null &&
                cleCarte != null
              "
            >
            </LoadingWrapper>
            <fondChloropeth
              v-if="
                donneesCartes &&
                indicateurChoisi &&
                carte.carte_geojson &&
                cleCarte
              "
              :key="cleCarte"
              :geojson="carte.carte_geojson"
              :onEachFeatureFunction="onEachFeatureFunction"
              :choisirCouleur="choisirCouleur"
              :couleursPalette="couleursPalette"
              :montrerLegende="false"
              :boundingBox="boundingBox"
              @update:bounds="
                console.log('boundsUpdate', $event);
                this.boundingBox = $event;
              "
              attribution='obso-alim.org <br>&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            >
              <template #contenu>
                <l-geo-json
                  v-if="qpv_geojson && menu.qpv_checkbox"
                  noBlockingAnimations="true"
                  :geojson="qpv_geojson"
                  :options="optionCoucheQPV"
                  :optionsStyle="geojsonQPV.options"
                />
                <l-geo-json
                  v-if="epci_geojson && menu.epci_checkbox"
                  noBlockingAnimations="true"
                  :geojson="epci_geojson"
                  :options="optionCoucheEPCI"
                  :optionsStyle="geojsonAdditionnel.options"
                />
                <div v-if="menu.points_aides_checkbox && solinum_lieux">
                  <l-marker
                    v-for="lieu in solinum_lieux"
                    :key="lieu.code_solinum_lieu"
                    :lat-lng="[lieu.pos_lat, lieu.pos_long]"
                  >
                    <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
                  </l-marker>
                </div>
                <img
                  :src="require(`@/assets/sponsors/OBSOALIM34 - LOGO bleu.png`)"
                  alt="Logo Obsoalim"
                  style="
                    width: 100px;
                    height: auto;
                    position: absolute;
                    right: 0;
                    z-index: 1000;
                    padding: 4px;
                    background-color: var(--couleur-blanc-80);
                  "
                />
              </template>
            </fondChloropeth>
            <div class="legende-et-boutons">
              <legende-chloropeth
                :couleursPalette="couleursPalette"
                class="legende"
                sens="row"
              ></legende-chloropeth>
              <div id="menu-boutons-principaux">
                <BoutonStandard
                  @click="
                    this.cartePourScreenshot = true;
                    prendreScreenshot('.titre-et-carte').then(() => {});
                  "
                  couleur="couleur-secondaire"
                  type="relief"
                  typeBouton="action"
                >
                  Exportez la carte</BoutonStandard
                >
                <BoutonStandard
                  v-if="!ajouterTooltip"
                  @click="ajoutTooltip"
                  couleur="couleur-secondaire"
                  type="encadre"
                  typeBouton="action"
                  >> Interprétez la carte</BoutonStandard
                >
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="indicateurChoisi && indicateurChoisi.indicateursynthetique"
          class="boite-information"
        >
          Pour les facteurs de risque sous secret statistique, le niveau de
          risque de précarité alimentaire fait l'objet d'une estimation
          détaillée dans la page
          <router-link to="/methode-de-calcul" class="lien">
            Méthode de calcul
          </router-link>
        </div>
      </div>
      <div id="menuParametres" v-if="!ajouterTooltip">
        <h3 style="font-weight: bold; min-height: 88px; line-height: 40px">
          Choix des paramètres
        </h3>
        <article>
          <h4 class="titres-parametres">Choisissez un territoire :</h4>
          <barreRechercheDepartements
            v-if="nomDepartement"
            :nomDepartementDefaut="nomDepartement"
            @departementChoisi="
              $router
                .push({
                  nom: 'Dashboard Territoire',
                  params: { codeDepartement: $event.code },
                  query: {
                    ancreFacteur: menu.facteurRisque.lienAncre,
                    _r: Date.now(),
                  },
                })
                .then(() => {
                  $router.go();
                })
            "
          ></barreRechercheDepartements>
        </article>
        <article>
          <h4 class="titres-parametres">Choisissez une échelle :</h4>

          <ul class="choix-niveau-territoire">
            <li class="territoire-active">Communes</li>
            <router-link
              :to="{
                name: 'Dashboard Territoire Iris',
                params: { codeDepartement: codeDepartement },
                query: { ancreFacteur: menu.facteurRisque.lienAncre },
              }"
              ><li>Iris</li></router-link
            >
          </ul>
        </article>
        <article style="height: 168px">
          <BoutonRadio
            nomRadio="indicateur"
            titreMenu="Choisissez un facteur de risque :"
            v-if="menu.facteurRisque"
            v-model="indicateurChoisi"
            :listeRadio="menu.facteurRisque.indicateurs"
            cléAffichée="nomIndicateur"
            clépourDéfaut="valeurDefaut"
            class="bloc-parametres"
          >
          </BoutonRadio>
          <router-link
            v-if="menu.facteurRisque"
            :to="{
              path: '/indicateurs',
              query: { facteur: menu.facteurRisque.lienAncre },
            }"
            class="texte-petit lien-petit"
            >En savoir plus sur les facteurs de risque</router-link
          >
        </article>
        <article
          v-if="
            donneesCartes &&
            indicateurChoisi &&
            !indicateurChoisi.indicateursynthetique
          "
        >
          <h4 class="titres-parametres">Valeurs</h4>
          <ul id="resultatsTerritoires" class="texte-petit">
            <li>Moyenne dans le département :</li>
            <li>{{ valeursIndicateursDepartement }}</li>
            <li>Moyenne pour la France entière :</li>
            <li>{{ valeursIndicateursFrance }}</li>
          </ul>
        </article>
        <article>
          <BoutonRadio
            nomRadio="territoireComparaison"
            titreMenu="Choisissez un territoire de référence :"
            :listeRadio="menu.listeRadioComparaison"
            v-model="menu.choixComparaison"
            cléAffichée="nomRadio"
            clépourDéfaut="valeurDefaut"
            class="bloc-parametres"
          />
        </article>
        <article>
          <DropDownMenu
            v-model="menu.facteurRisque"
            titreMenu="Choisissez une autre catégorie de facteurs de risque :"
            :listeSousElements="facteursRisqueListe"
            cléPourSélection="lienAncre"
            :selectedValeur="this.selectedValeur"
            class="bloc-parametres"
          >
          </DropDownMenu>
        </article>
        <article>
          <legend class="titres-parametres">Affichez sur la carte :</legend>
          <div id="menu-surcouches">
            <div>
              <input
                type="checkbox"
                id="epci"
                name="epci"
                v-model="menu.epci_checkbox"
                class="checkbox"
              />
              <label for="epci"
                >Les Etablissements Publics de Coopération Intercommunale
                (EPCI)</label
              >
            </div>

            <div>
              <input
                type="checkbox"
                id="qpv"
                name="qpv"
                v-model="menu.qpv_checkbox"
                class="checkbox"
              />
              <label for="qpv"
                >Les Quartiers prioritaires de la Ville (QPV)</label
              >
            </div>

            <div>
              <input
                type="checkbox"
                id="points-aides"
                name="points-aides"
                v-model="menu.points_aides_checkbox"
                class="checkbox"
              />
              <label for="points-aides"
                >Les points de solidarité alimentaire
                <a
                  href="https://soliguide.fr/"
                  style="color: var(--couleur-primaire) !important"
                  >(données Solinum)</a
                ></label
              >
            </div>
          </div>
        </article>
      </div>
      <div v-if="ajouterTooltip" class="boite-interpretation">
        <h3><b>Interprétez la carte :</b></h3>
        <article class="texteInterpretation">
          <span id="interpretationEleve" class="boxInterpretation">
            <h4
              style="
                background-color: #800f2f;
                color: white;
                width: fit-content;
                padding-left: 2px;
              "
            >
              <b>Risque de précarité alimentaire le plus élevé :</b>
            </h4>
            <p>
              {{ menu.facteurRisque.reperesInterpretation.tresEleve }}
            </p>
          </span>
          <span id="interpretationBas" class="boxInterpretation">
            <h4
              style="
                background-color: #fff0f3;
                width: fit-content;
                padding-left: 2px;
              "
            >
              <b>Risque de précarité alimentaire le plus bas:</b>
            </h4>
            <p>
              {{ menu.facteurRisque.reperesInterpretation.tresBas }}
            </p>
          </span>
        </article>
        <BoutonStandard
          @click="ajoutTooltip"
          couleur="couleur-secondaire"
          type="encadre"
          typeBouton="action"
        >
          Revenez aux choix des paramètres ></BoutonStandard
        >
      </div>
    </div>
  </div>
</template>

<script>
import BoutonRadio from "@packages/ui-components/src/composants/basNiveau/BoutonRadio.vue";
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import DropDownMenu from "@packages/ui-components/src/composants/basNiveau/DropDownMenu.vue";
import fondChloropeth from "@packages/ui-components/src/composants/cartographie/fondChloropeth.vue";
import legendeChloropeth from "@packages/ui-components/src/composants/cartographie/legendeChloropeth.vue";
import LoadingWrapper from "@packages/ui-components/src/composants/chargement/LoadingWrapper.vue";
import facteurs_de_risques from "@/assets/donnees/facteurs_de_risques.json";
import utils from "@/utils/utils.js";
import fonction_api from "@/utils/fonction_api.js";
import valeurs_indicateurs_departements from "@/assets/donnees/valeurs_indicateurs_departements.json";
import prendreScreenshot from "@/utils/prendreScreenshot.js";
import "leaflet/dist/leaflet.css";
import { LGeoJson, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";
import iconlieu from "@/assets/img/icon-lieu.svg";
import geojson_api from "@packages/utils/GeoJsonReader.js";
import exportDataHelper from "@packages/utils/ExportDataHelper.js";
import barreRechercheDepartements from "@/composants/barreRechercheDepartements.vue";

export default {
  name: "dashboardTerritoires",
  components: {
    LoadingWrapper,
    barreRechercheDepartements,
    BoutonStandard,
    DropDownMenu,
    BoutonRadio,
    fondChloropeth,
    legendeChloropeth,
    LGeoJson,
    LIcon,
    LMarker,
  },
  data() {
    return {
      iconUrl: iconlieu,
      iconSize: 8,
      codeDepartement: "01",
      menu: {
        facteurRisque: Object,
        choixComparaison: null,
        listeRadioComparaison: [
          {
            nomRadio: "Comparer avec communes du département",
            cléValeur: "jenks_cut_dep",
            valeurDefaut: true,
            echelleComparaison: null,
          },
          {
            nomRadio: "Comparer avec communes de la France entière",
            cléValeur: "jenks_cut_nat",
            valeurDefaut: false,
            echelleComparaison: "France Entière",
          },
        ],
        epci_checkbox: null,
        qpv_checkbox: null,
        points_aides_checkbox: null,
      },
      carte: {
        carte_geojson: null,
      },
      facteursRisqueListe: facteurs_de_risques,
      selectedValeur: this.$route.query.ancreFacteur,
      indicateurChoisi: null,
      geojson: null,
      epci_geojson: null,
      qpv_geojson: null,
      carteChargement: false,
      zoom: null,
      boundingBox: null,
      nomDepartement: null,
      departement: null,
      donneesCartes: null,
      cleCarte: null,
      couleursPalette: [
        { couleur: "#FFF0F3", label: "Le plus bas" },
        { couleur: "#FFB3C1", label: "Bas" },
        { couleur: "#FF758F", label: "Moyen" },
        { couleur: "#C9184A", label: "Elevé" },
        { couleur: "#800F2F", label: "Le plus élevé" },
        { couleur: "#C0C0C0", label: "Secret statistique" },
        {
          couleur: "#f9b000",
          label: "Points d'aide alimentaire",
          type: "rond",
        },
        { couleur: "#5785E9", label: "QPV", type: "carré" },
        { couleur: "#5785E9", label: "EPCI", type: "carré-vide" },
      ],
      legendeAdditionnelle: [],
      ajouterTooltip: false,
      geojsonAdditionnel: {
        options: {
          weight: 1.5,
          opacity: 1,
          fillOpacity: 0,
        },
      },
      geojsonQPV: {
        options: {
          weight: 1.5,
          opacity: 1,
          fillOpacity: 0.8,
        },
      },
      solinum_lieux: null,
      cartePourScreenshot: false,
    };
  },
  methods: {
    prendreScreenshot,
    téléchargerLesDonnées() {
      console.log("this.donneesCartes pour le workbook", this.donneesCartes);
      exportDataHelper.exportToWorkbook(
        this.donneesCartes,
        "workbook_de_test_obsoalim"
      );
    },
    choisirIndicateur() {
      this.indicateurChoisi = this.menu.facteurRisque.indicateurs.find(
        (indicateur) => indicateur.valeurDefaut === true
      );
    },
    async recupererDonneesChangerIndicateurs(facteurRisque) {
      this.donneesCartes = await fonction_api.chercheDonneesObsoalim(
        facteurRisque,
        this.codeDepartement,
        "commune_old"
      );
      await this.choisirIndicateur();
    },
    choisirCouleur(feature) {
      /* TODO: changer par code territoire => modifier les geojson */
      var donneeCommune = this.donneesCartes.find(
        (donnees) =>
          donnees.code_commune === "com" + feature.properties.code_commune &&
          this.indicateurChoisi.idIndicateur === donnees.id_indicateur
      );
      if (donneeCommune) {
        if (
          donneeCommune[this.menu.choixComparaison.cléValeur] ===
          "1 - Le plus bas"
        ) {
          return "#FFF0F3";
        } else if (
          donneeCommune[this.menu.choixComparaison.cléValeur] === "2 - Bas"
        ) {
          return "#FFB3C1";
        } else if (
          donneeCommune[this.menu.choixComparaison.cléValeur] === "3 - Moyen"
        ) {
          return "#FF758F";
        } else if (
          donneeCommune[this.menu.choixComparaison.cléValeur] === "4 - Elevé"
        ) {
          return "#C9184A";
        } else if (
          donneeCommune[this.menu.choixComparaison.cléValeur] ===
          "5 - Le plus élevé"
        ) {
          return "#800F2F";
        } else {
          return "#C0C0C0";
        }
      }
    },
    genererCle() {
      if (!this.indicateurChoisi || !this.menu.choixComparaison) {
        return null;
      } else {
        console.log("Génération de la clé", this.cleCarte);
        this.cleCarte =
          this.indicateurChoisi.idIndicateur +
          this.menu.choixComparaison.nomRadio;
      }
    },
    ajoutTooltip() {
      console.log("Ajout du tooltip");
      this.ajouterTooltip = !this.ajouterTooltip;
    },
  },
  computed: {
    optionCoucheEPCI() {
      return {
        style: this.geojsonAdditionnel.options,
        interactive: false,
      };
    },
    optionCoucheQPV() {
      return {
        style: this.geojsonAdditionnel.options,
        interactive: true,
        onEachFeature: (feature, layer) => {
          layer.bindTooltip(
            "Nom QPV : " + feature.properties.libelle_territoire
          );
        },
      };
    },
    onEachFeatureFunction() {
      return (feature, layer) => {
        const donnees = this.donneesCartes.find(
          (donnees) =>
            donnees.code_commune === "com" + feature.properties.code_commune &&
            this.indicateurChoisi.idIndicateur === donnees.id_indicateur
        );
        if (donnees) {
          if (this.indicateurChoisi.indicateursynthetique) {
            layer.bindTooltip(
              "<div>Nom de la commune:" +
                feature.properties.libelle_commune +
                "</br>" +
                "<div>EPCI de la commune:" +
                feature.properties.libelle_epci +
                "</br>" +
                "Population de la commune (2018):" +
                utils.formatterNombre(feature.properties.population, "entier") +
                "</br>" +
                "Risque de précarité alimentaire: " +
                donnees[this.menu.choixComparaison.cléValeur] +
                "</br>" +
                "Territoire de Référence: " +
                this.territoireComparaison +
                "</div>",
              { permanent: false, sticky: true }
            );
          } else if (donnees["secret_stat"]) {
            layer.bindTooltip(
              "<div>Nom de la commune:" +
                feature.properties.libelle_commune +
                "</br>" +
                "<div>EPCI de la commune:" +
                feature.properties.libelle_epci +
                "</br>" +
                "Population de la commune (2018):" +
                utils.formatterNombre(feature.properties.population, "entier") +
                "</br>" +
                "</br>" +
                "Valeur Indicateur: Secret Statistique </br>Risque de précarité alimentaire: Secret Statistique</div>",
              { permanent: false, sticky: true }
            );
          } else {
            layer.bindTooltip(
              "<div>Nom de la commune:" +
                feature.properties.libelle_commune +
                "</br>" +
                "<div>EPCI de la commune:" +
                feature.properties.libelle_epci +
                "</br>" +
                "Population de la commune (2018):" +
                utils.formatterNombre(feature.properties.population, "entier") +
                "</br>" +
                "Valeur Indicateur: " +
                utils.formatterNombre(
                  donnees["valeur_indicateur"],
                  this.indicateurChoisi.typeIndicateur
                ) +
                "</br>" +
                "Risque de précarité alimentaire: " +
                donnees[this.menu.choixComparaison.cléValeur] +
                "</div>",
              { permanent: false, sticky: true }
            );
          }
        }
      };
    },
    generatekey() {
      if (!this.indicateurChoisi || !this.menu.choixComparaison) {
        return null;
      } else {
        return (indicateurChoisi, choixComparaison) =>
          indicateurChoisi.idIndicateur + choixComparaison.nomRadio;
      }
    },
    valeursIndicateursDepartement() {
      return utils.formatterNombre(
        valeurs_indicateurs_departements.filter(
          (valeur) =>
            valeur.code_departement === this.codeDepartement &&
            valeur.id_indicateur === this.indicateurChoisi.idIndicateur
        )[0]["valeur_indicateur"],
        this.indicateurChoisi.typeIndicateur
      );
    },
    valeursIndicateursFrance() {
      return utils.formatterNombre(
        valeurs_indicateurs_departements.filter(
          (valeur) =>
            valeur.code_departement === "France" &&
            valeur.id_indicateur === this.indicateurChoisi.idIndicateur
        )[0]["valeur_indicateur"],
        this.indicateurChoisi.typeIndicateur
      );
    },
    territoireComparaison() {
      if (
        this.menu.choixComparaison.nomRadio ===
        "Comparer avec communes du département"
      ) {
        return this.nomDepartement;
      } else {
        return "France";
      }
    },
  },
  async mounted() {
    this.codeDepartement = this.$route.params.codeDepartement;
    this.menu.listeRadioComparaison.echelleComparaison = this.codeDepartement;
    this.departement = utils.trouverDepartement(this.codeDepartement);
    this.nomDepartement = utils.trouverDepartement(this.codeDepartement).nom;
    if (this.selectedValeur === undefined) {
      this.menu.facteurRisque = this.facteursRisqueListe[0];
      this.selectedValeur = this.facteursRisqueListe[0].lienAncre;
    } else {
      console.log("Selected valeur", this.selectedValeur);
      this.menu.facteurRisque = this.facteursRisqueListe.find(
        (facteur) => facteur.lienAncre === this.selectedValeur
      );
    }
    geojson_api
      .getGeoJson(this.codeDepartement, "departement", "commune")
      .then((geojson) => {
        this.carte.carte_geojson = geojson;
      });
    this.donneesCartes = await fonction_api.chercheDonneesObsoalim(
      this.menu.facteurRisque.lienAncre,
      this.codeDepartement,
      "commune_old"
    );

    this.indicateurChoisi = this.menu.facteurRisque.indicateurs.find(
      (indicateur) => indicateur.valeurDefaut === true
    );
    this.menu.choixComparaison = this.menu.listeRadioComparaison.find(
      (comparaison) => comparaison.valeurDefaut === true
    );
    fonction_api
      .getDonneesSolinum(`dep${this.codeDepartement}`)
      .then((response) => {
        this.solinum_lieux = response;
      });
    geojson_api
      .getGeoJson(`dep${this.codeDepartement}`, "departement", "qpv")
      .then((geojson) => {
        this.qpv_geojson = geojson;
      });
    geojson_api
      .getGeoJson(`dep${this.codeDepartement}`, "departement", "epci")
      .then((geojson) => {
        this.epci_geojson = geojson;
      });
  },
  watch: {
    "menu.facteurRisque": {
      handler(nouvelleVal) {
        this.recupererDonneesChangerIndicateurs(nouvelleVal.lienAncre);
      },
    },
    indicateurChoisi() {
      this.genererCle();
    },
    "menu.choixComparaison": {
      handler() {
        this.genererCle();
      },
    },
  },
};
</script>
<style scoped>
#dashboard {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: var(--dsem2) var(--dsem2);
  gap: var(--dsem2);
  height: calc(100vh - var(--hauteur-barre-navigation));
}

.lien {
  color: var(--couleur-primaire);
}

.lien:hover {
  color: var(--couleur-primaire-sombre);
}

#dashboardContenu {
  display: grid;
  grid-template-columns: 6fr 2fr;
  flex-grow: 1;
  gap: var(--dsem2);
  height: 100%;
}

#menuParametres {
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
  height: 100%;
  overflow-y: scroll;
  padding: var(--dsem);
  border-left: 1px solid var(--couleur-gris-80);
}

.titre-et-carte {
  display: flex;
  flex-direction: column;
  height: 80vh;
  gap: var(--dsem2);
  padding: var(--dsem);
}

#carteLégendée {
  flex-grow: 1;
}

.legende-et-boutons {
  display: flex;
  flex-direction: row;
  gap: var(--dsem2);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.legende {
  max-width: 60% !important;
  text-align: left;
}

#carte {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#resultatsTerritoires {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 1fr;
  border: 1px solid var(--couleur-gris-80);
  grid-gap: 1px;
  background-color: var(--couleur-gris-80);
}

#resultatsTerritoires li {
  background-color: white;
  text-align: left;
  padding: 0 var(--dsem);
}

.lien-petit {
  color: var(--couleur-primaire);
}

.lien-petit:hover {
  color: var(--couleur-primaire-sombre);
}

.modal {
  width: fit-content;
  height: 100%;
  background-color: bisque;
  width: 2fr;
}

.boite-interpretation {
  padding: var(--dsem2);
}

.texteInterpretation {
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
  text-align: left;
  padding: var(--dsem2) 0;
}

#interpretationEleve span {
  color: "#800F2F";
}

.boxInterpretation h3 {
  font-weight: 700;
}

article {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

#menu-surcouches {
  text-align: left;
  display: flex;
  flex-direction: column;
}

#menu-boutons-principaux {
  display: flex;
  flex-direction: row;
  gap: var(--dsem2);
  flex-grow: 1;
  justify-content: flex-end;
}

.choix-niveau-territoire {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  width: 80%;
  background-color: var(--couleur-orange-40);
  border-radius: var(--dsem2);
}

.territoire-active {
  background-color: var(--couleur-secondaire);
  border-radius: var(--dsem2);
  padding: 4px;
  color: var(--couleur-blanc);
}

.bloc-parametres >>> h3,
.bloc-parametres >>> .titreMenu,
.titres-parametres {
  font-weight: bold;
}

.checkbox {
  margin-right: var(--dsem);
}

.logo-screenshot {
  display: block !important;
}

.boite-information {
  background-color: var(--couleur-orange-20);
  color: var(--couleur-orange);
  padding: var(--dsem);
  border: 1px solid var(--couleur-orange-80);
}

.text-petit {
  text-align: left;
}
</style>
