<template>
  <div id="app">
    <h1>Page Iris Test</h1>
    <fondChloropeth
      v-if="carteChargement && donneesFilosofi2018"
      :key="cleCarte"
      :geojson="geojson"
      :onEachFeatureFunction="onEachFeatureFunction"
      :choisirCouleur="choisirCouleur"
      :couleursPalette="couleursPalette"
      :montrerLegende="false"
    />
  </div>
</template>

<script>
import fondChloropeth from "@packages/ui-components/src/composants/cartographie/fondChloropeth.vue";
import api from "@/utils/fonction_api.js";
import geojson_api from "@packages/utils/GeoJsonReader.js";

export default {
  components: {
    fondChloropeth,
  },
  data() {
    return {
      geojson: null,
      cleCarte: "1",
      couleursPalette: [
        { couleur: "#FFF0F3", label: "Très bas" },
        { couleur: "#FFB3C1", label: "Bas" },
        { couleur: "#FF758F", label: "Moyen" },
        { couleur: "#C9184A", label: "Elevé" },
        { couleur: "#800F2F", label: "Très élevé" },
        { couleur: "#C0C0C0", label: "Secret statistique" },
      ],
      carteChargement: false,
      donneesFilosofi2017: null,
      donneesFilosofi2018: null,
      donneesFilosofi2020: null,
    };
  },
  methods: {
    async recupererGeojson() {
      geojson_api.getGeoJson("33", "departement", "iris").then((response) => {
        this.geojson = response;
        this.carteChargement = true;
        console.log("geojson", this.geojson);
      });
    },
    async getdonneesFilosofi2018() {
      api
        .getDonneesFilosofiTerritoires("dep33", "2018", "iris")
        .then((response) => {
          console.log("response", response);
          this.donneesFilosofi2018 = response;
          console.log("donneesFilosofi2018", this.donneesFilosofi2018);
        });
    },
    async getdonneesFilosofi2017() {
      api
        .getDonneesFilosofiTerritoires("dep33", "2017", "iris")
        .then((response) => {
          this.donneesFilosofi2017 = response;
        });
    },
    async getdonneesFilosofi2020() {
      api
        .getDonneesFilosofiTerritoires("dep33", "2020", "iris")
        .then((response) => {
          this.donneesFilosofi2020 = response;
        });
    },
    choisirCouleur(feature) {
      const codeIris = feature.properties.code_iris;
      console.log("codeIris", codeIris);
      const donnees2018 = this.donneesFilosofi2018["resultats"];
      const donnees2017 = this.donneesFilosofi2017["resultats"];
      const donnees2020 = this.donneesFilosofi2020["resultats"];
      const donnees2018Population =
        donnees2020["Demandeur d'emploi en fin de mois"]["iris" + codeIris];
      const donnees2017Population =
        donnees2017["Nombre de personnes de 15 à 24 ans"]["iris" + codeIris] +
        donnees2017["Nombre de personnes de 25 à 54 ans"]["iris" + codeIris] +
        donnees2018["Nombre de personnes de 55 à 64 ans"]["iris" + codeIris];
      console.log("2020", donnees2018Population);
      console.log(
        donnees2017["Nombre de personnes de 15 à 24 ans"]["iris" + codeIris]
      );
      console.log(
        donnees2017["Nombre de personnes de 25 à 54 ans"]["iris" + codeIris]
      );
      console.log(
        "65",
        donnees2018["Nombre de personnes de 55 à 64 ans"]["iris" + codeIris]
      );
      const donnees2018txChomage =
        donnees2018Population / donnees2017Population;
      console.log("donnees2018txChomage", donnees2018txChomage);
      if (donnees2018txChomage > 0 && donnees2018txChomage < 0.17) {
        return "#FFF0F3";
      } else if (donnees2018txChomage < 0.2) {
        return "#FFB3C1";
      } else if (donnees2018txChomage < 0.24) {
        return "#FF758F";
      } else if (donnees2018txChomage < 0.28) {
        return "#C9184A";
      } else if (donnees2018txChomage > 0) {
        return "#800F2F";
      } else {
        return "#C0C0C0";
      }
    },
    onEachFeatureFunction(feature, layer) {
      layer.on({
        mouseover: this.highlightFeature,
        mouseout: this.resetHighlight,
        click: this.zoomToFeature,
      });
    },
  },
  beforeMount() {
    this.recupererGeojson();
    this.getdonneesFilosofi2017();
    this.getdonneesFilosofi2018();
    this.getdonneesFilosofi2020();
  },
};
</script>

<style scoped>
#app {
  width: 1000px;
  height: 1000px;
  border: 1px solid black;
}
</style>
>
