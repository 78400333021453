<template>
  <div>
    <div v-if="Object.keys(get_server_errors()).length > 0">
      <div v-for="(error, url) in get_server_errors()" v-bind:key="url">
        <p>
          Calling url <i>{{ url }}</i> gave this error :
        </p>
        <p class="erreur">⚠️ {{ error }}</p>
      </div>
    </div>
    <div v-if="errors != null">
      <p class="erreur">
        ⚠️ {{ errors.Exception ? errors.Exception : errors }}
      </p>
    </div>
    <div v-else-if="!data_loaded">
      <p v-if="loading_advancement != null">
        Loading data {{ loading_advancement.done }}/{{
          loading_advancement.total
        }}
      </p>
      <p v-else>Chargement des données</p>
      <button class="lds-dual-ring"></button>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingWrapper",
  props: {
    data_loaded: Boolean,
    errors: Object,
    loading_advancement: Object,
  },
  components: {},
  data() {
    return {
      selected_currency: null,
    };
  },
  mounted: function () {},
  computed: {},
  methods: {
    get_server_errors: function () {
      return [];
    },
  },
};
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--couleur-primaire);
  border-color: var(--couleur-primaire) transparent var(--couleur-primaire)
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
