<template>
  <grilleStandard>
    <template v-slot:contenu>
      <div>
        <div v-if="archieData" class="content is-medium">
          <template v-for="(contenu, index) in archieData.content" :key="index">
            <vue-showdown
              class="aPropos"
              v-if="contenu.type == 'text'"
              :markdown="contenu.value"
            ></vue-showdown>
            <vue-showdown
              v-if="contenu.type == 'list'"
              :markdown="concateneList(contenu.value)"
            ></vue-showdown>
            <p></p>
          </template>
        </div>
      </div>
    </template>
  </grilleStandard>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import "archieml";
import globalMethodologie from "@/assets/description_indicateurs/aPropos.archieml";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";

export default {
  name: "IndicateursVIew",
  components: { VueShowdown, grilleStandard },
  data() {
    return {
      archieData: null,
      facteurs: null,
    };
  },
  mounted() {
    this.logArchieMlContenu();
  },
  methods: {
    logArchieMlContenu() {
      console.log("=> Log contenu");
      const archieData = globalMethodologie;
      this.archieData = archieData;
      console.log(this.archieData);
      return this.archieData;
    },
    concateneList(list) {
      var text = "";
      for (let i = 0; i < list.length; i++) {
        text += "* " + list[i] + "\n";
      }
      console.log(text);

      return text;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 32px 0px;
  text-align: left;
  margin: auto;
}
.content {
  padding: 32px 0px;
  text-align: left;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
}

.infographie {
  height: 100px;
  width: 100px;
}
</style>

<style>
.aPropos a {
  color: var(--couleur-primaire);
}

#leprojetobsoalim34 {
  color: var(--couleur-primaire-sombre);
}
</style>
