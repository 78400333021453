module.exports = {
	"content": [
		{
			"type": "text",
			"value": "# Le projet Obsoalim34"
		},
		{
			"type": "text",
			"value": "Cet outil a été développé dans le cadre du projet [OBSOALIM34](https://obso-alim.org/) (Observatoire des Solidarités Alimentaires de l’Hérault), initié en 2020 grâce à deux financements, l’un perçu dans le cadre du plan de soutien aux associations de lutte contre la précarité par l'Etat (via la DDETS 34) et l’autre du Conseil Départemental de l’Hérault. Il est co-porté par la Chaire Unesco Alimentations du monde de l'Institut Agro Montpellier et du Cirad et par la Fédération des Acteurs de la Solidarité Occitanie (FAS Occitanie), et s’appuie depuis ses débuts sur un comité de pilotage composé de personnalités du Conseil Départemental de l’Hérault, de la DDETS 34, de la Ville de Montpellier, de Montpellier Méditerranée Métropole, de la Région Occitanie, de l’association Solinum, du SIAO 34, d'INRAE, de la [FAS Occitanie](https://www.federationsolidarite.org/regions/occitanie/) et de la [Chaire Unesco Alimentations du monde](https://www.chaireunesco-adm.com/)"
		},
		{
			"type": "text",
			"value": "L’un des objectifs du projet consistait à produire une **méthodologie de caractérisation de la précarité alimentaire à l’échelle territoriale**. En effet, il n’existe pas à ce jour en France d’enquêtes menées sur le sujet de façon régulière, ni à l’échelle nationale, ni aux échelles locales."
		},
		{
			"type": "text",
			"value": "La précarité alimentaire est pour l'instant majoritairement estimée à partir des chiffres communiqués par les associations d’aide alimentaire portant sur le volume de denrées distribuées ou sur le nombre de personnes bénéficiaires. Si ces chiffres sont utiles pour une diversité d’acteurs en termes de suivi de l’évolution de la précarité alimentaire ou de pilotage d’actions, ils ne constituent pas une réponse satisfaisante. D’une part, ils ne rendent pas compte de l’ampleur réelle de la situation car ils sont aveugles sur les situations de non-recours (d’après [une étude du Crédoc et d’INRAE Bordeaux](https://www.credoc.fr/publications/la-debrouille-des-personnes-qui-ne-mangent-pas-toujours-a-leur-faim), près de la moitié des personnes en situation de précarité alimentaire ne se rendraient pas à l’aide alimentaire, pour des raisons de honte, de difficultés d’accès, de manque d’informations, etc.). D’autre part, ces chiffres ne reflètent pas la diversité des situations de précarité alimentaire (pouvant être liées à des contraintes économiques, sociales, environnementales, informationnelles). Enfin, ces données remontées par les associations d’aide alimentaire ne sont pas basées sur les mêmes méthodes de comptage, et ne sont pas facilement accessibles aux échelles territoriales."
		},
		{
			"type": "text",
			"value": "Le travail engagé dans le cadre d'Obsoalim sur le diagnostic de la précarité alimentaire à l’échelle locale a été piloté par la Chaire Unesco Alimentations du monde, le Cirad, l’INRAE (UMR Innovation) et Montpellier Méditerranée Métropole. Il avait pour but de fournir un état des lieux de la précarité alimentaire actualisable dans le temps et réplicable dans d’autres territoires que l’Hérault. Superposé à une cartographie des points de solidarités alimentaires, cet état des lieux permet d’identifier des **zones blanches**, c’est-à-dire des zones à risque non couvertes par des dispositifs de lutte contre la précarité alimentaire. Ce travail a nourri et été largement nourri par les réflexions menées dans le **Groupe de Travail n°1 du Comité National de Coordination de la Lutte contre Précarité Alimentaire** (COCOLUPA) en 2021 et 2022."
		},
		{
			"type": "text",
			"value": "Ce travail de caractérisation de la précarité alimentaire à l’échelle territoriale a donné lieu à [une première publication](https://www.chaireunesco-adm.com/No24-Une-approche-territoriale-des-facteurs-de-precarite-alimentaire-utilisant), et se concrétise dans le présent outil. Pour autant, il montre lui-même **un certain nombre de limites** ([voir page Méthode de calcul](/methode-de-calcul)) : choix des indicateurs contraints par leur disponibilité, les indicateurs choisis n’étant pas toujours ceux qui reflètent le mieux les situations ; choix méthodologiques basés sur un certain nombre d’hypothèses ; difficulté à chiffrer le nombre de personnes à risque dans un territoire donné ; difficultés à prendre en compte à ce stade la dimension « vécue » de la précarité alimentaire… Aussi, ce travail ne représente qu’une étape, et se veut être **un support de réflexion collective** pour tendre vers une caractérisation toujours plus précise de la précarité alimentaire dans les territoires."
		}
	]
};