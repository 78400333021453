<template>
  <grilleStandard>
    <template v-slot:contenu>
      <div class="contenu">
        <div class="titre-sous-titre">
          <div style="padding-bottom: var(--dsem2)">
            <h1 class="couleur-primaire-sombre titre">
              Données des facteurs de risque de précarité alimentaire
            </h1>
            Retrouvez dans cet espace les données-clés permettant de
            caractériser la situation de votre territoire.
          </div>
          <h2 class="couleur-primaire">
            <b>Choisissez un département :</b>
          </h2>
        </div>
        <barreRechercheDepartements
          @departementChoisi="
            departementChoisi = $event;
            departementChoisi.code_territoire = 'dep' + $event.code;
          "
        ></barreRechercheDepartements>
        <div
          v-if="
            this.departementChoisi && this.listeIris && this.listeIrisparCommune
          "
        >
          <div class="bloc-reglages">
            <div class="titre-et-encadre-reglages">
              <h3 class="couleur-primaire">
                <b>Choisissez un ou plusieurs territoires : </b>
              </h3>
              <div class="encadre-reglages">
                <div class="titre-encadre">
                  <h3
                    :class="[
                      echelleGeographiqueChoisie === 'commune'
                        ? 'titre-actif'
                        : '',
                      true ? 'titre' : '',
                    ]"
                    @click="
                      echelleGeographiqueChoisie = 'commune';
                      selection.indicateursChoisis = [];
                      territoiresChoisis = [];
                    "
                  >
                    Communes
                  </h3>
                  <h3
                    :class="[
                      echelleGeographiqueChoisie === 'iris'
                        ? 'titre-actif'
                        : '',
                      true ? 'titre' : '',
                    ]"
                    @click="
                      echelleGeographiqueChoisie = 'iris';
                      selection.indicateursChoisis = [];
                      territoiresChoisis = [];
                    "
                  >
                    IRIS
                  </h3>
                </div>
                <div
                  v-if="
                    echelleGeographiqueChoisie === 'commune' &&
                    listeIrisparCommune &&
                    listeCommunes
                  "
                >
                  <input
                    type="search"
                    class="recherche-barre-reglages"
                    placeholder="Recherchez une ou plusieurs communes"
                    v-model="interaction.valeurRechercheTerritoire"
                  />
                  <ul class="liste-choix-possibles liste-communes">
                    <li
                      v-for="territoire in listeCommunes.filter((territoire) =>
                        territoire.libelle_territoire
                          .toLowerCase()
                          .includes(
                            interaction.valeurRechercheTerritoire.toLowerCase()
                          )
                      )"
                      :key="territoire.libelle_territoire"
                    >
                      <input
                        type="checkbox"
                        :id="territoire"
                        :value="territoire"
                        v-model="territoiresChoisis"
                      />
                      <label>{{ territoire.libelle_territoire }}</label>
                    </li>
                  </ul>

                  <div class="boutons-encadre">
                    <BoutonStandard
                      class="bouton-selection"
                      type="encadre"
                      couleur="couleur-primaire"
                      typeBouton="action"
                      @click="territoiresChoisis = listeCommunes"
                    >
                      Tout sélectionner</BoutonStandard
                    >
                    <BoutonStandard
                      class="bouton-selection"
                      type="encadre"
                      couleur="couleur-primaire"
                      typeBouton="action"
                      @click="territoiresChoisis = []"
                    >
                      Tout désélectionner</BoutonStandard
                    >
                  </div>
                  <ul class="liste-elements-choisis">
                    <li
                      v-for="territoire in trierListeObjets(
                        territoiresChoisis,
                        'libelle_territoire'
                      )"
                      :key="territoire.libelle_territoire"
                      class="elements-choisis"
                    >
                      <p>{{ territoire.libelle_territoire }}</p>
                      <img
                        class="logo-croix"
                        :src="require('@/assets/icons/croix.svg')"
                        alt="croix"
                        width="12"
                        height="12"
                        @click="
                          territoiresChoisis = territoiresChoisis.filter(
                            (territoireFiltre) =>
                              territoireFiltre.libelle_territoire !==
                              territoire.libelle_territoire
                          )
                        "
                      />
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    v-if="
                      echelleGeographiqueChoisie == 'iris' &&
                      listeIrisparCommune
                    "
                  >
                    <input
                      type="search"
                      class="recherche-barre-reglages"
                      placeholder="Recherchez un ou des IRIS"
                      v-model="interaction.valeurRechercheTerritoire"
                    />
                    <ul class="liste-choix-possibles">
                      <li
                        v-for="(irisListe, commune) in listeIrisparCommune"
                        :key="commune"
                      >
                        <b>{{ commune }}</b>
                        <ul class="sous-liste">
                          <li v-for="iris in irisListe" :key="iris">
                            <input
                              type="checkbox"
                              :id="iris"
                              :value="iris"
                              v-model="territoiresChoisis"
                            />
                            <label :for="iris">{{
                              iris.libelle_territoire
                            }}</label>
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <div class="boutons-encadre">
                      <BoutonStandard
                        class="bouton-selection"
                        type="encadre"
                        couleur="couleur-primaire"
                        typeBouton="action"
                        @click="territoiresChoisis = listeIris"
                      >
                        Tout sélectionner</BoutonStandard
                      >
                      <BoutonStandard
                        class="bouton-selection"
                        type="encadre"
                        couleur="couleur-primaire"
                        typeBouton="action"
                        @click="territoiresChoisis = []"
                      >
                        Tout désélectionner</BoutonStandard
                      >
                    </div>
                    <ul class="liste-elements-choisis">
                      <li
                        v-for="territoire in trierListeObjets(
                          territoiresChoisis,
                          'libelle_territoire'
                        )"
                        :key="territoire.libelle_territoire"
                        class="elements-choisis"
                      >
                        {{ territoire.libelle_commune }} -
                        {{ territoire.libelle_territoire }}
                        <img
                          class="logo-croix"
                          :src="require('@/assets/icons/croix.svg')"
                          alt="croix"
                          width="12"
                          height="12"
                          @click="
                            territoiresChoisis = territoiresChoisis.filter(
                              (territoireFiltre) =>
                                territoireFiltre.libelle_territoire !==
                                territoire.libelle_territoire
                            )
                          "
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="titre-et-encadre-reglages">
              <h3 class="couleur-primaire">
                <b>Choisissez un ou plusieurs indicateurs :</b>
              </h3>
              <div class="encadre-reglages">
                <h3 class="titre-actif titre titre-encadre">Indicateurs</h3>
                <input
                  type="search"
                  class="recherche-barre-reglages"
                  placeholder="Recherchez un ou plusieurs indicateurs"
                  v-model="interaction.valeurRechercheIndicateur"
                />
                <ul class="liste-choix-possibles">
                  <li
                    v-for="facteur in facteurs_de_risques.filter(
                      (facteur) => facteur?.lienAncre !== 'synthetique'
                    )"
                    :key="facteur"
                  >
                    <b>{{ facteur.nomFacteur }}</b>
                    <ul
                      v-if="facteur[this.cléListeIndicateurs]"
                      class="sous-liste"
                    >
                      <li
                        v-for="indicateur in facteur[
                          this.cléListeIndicateurs
                        ].filter(
                          (indicateur) =>
                            indicateur.nomIndicateur
                              .toLowerCase()
                              .includes(
                                interaction.valeurRechercheIndicateur.toLowerCase()
                              ) && indicateur?.indicateursynthetique !== true
                        )"
                        :key="indicateur"
                      >
                        <input
                          type="checkbox"
                          :id="indicateur"
                          :value="indicateur"
                          v-model="selection.indicateursChoisis"
                        />
                        <label :for="indicateur">{{
                          indicateur.nomIndicateur
                        }}</label>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="boutons-encadre">
                  <BoutonStandard
                    class="bouton-selection"
                    type="encadre"
                    couleur="couleur-primaire"
                    typeBouton="action"
                    @click="
                      selection.indicateurChoisi = choisirTouslesIndicateurs()
                    "
                  >
                    Tout sélectionner</BoutonStandard
                  >
                  <BoutonStandard
                    class="bouton-selection"
                    type="encadre"
                    couleur="couleur-primaire"
                    typeBouton="action"
                    @click="selection.indicateursChoisis = []"
                  >
                    Tout désélectionner</BoutonStandard
                  >
                </div>
                <ul class="liste-elements-choisis">
                  <li
                    v-for="indicateur in indicateursChoisisCroissants"
                    :key="indicateur.nomIndicateur"
                    class="elements-choisis"
                  >
                    {{ indicateur.nomIndicateur }}
                    <img
                      class="logo-croix"
                      :src="require('@/assets/icons/croix.svg')"
                      alt="croix"
                      width="12"
                      height="12"
                      @click="
                        selection.indicateursChoisis =
                          selection.indicateursChoisis.filter(
                            (indicateurFiltre) =>
                              indicateurFiltre.nomIndicateur !==
                              indicateur.nomIndicateur
                          )
                      "
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="bouton-actions">
            <BoutonStandard typeBouton="action" @click="téléchargerLesDonnées()"
              >Téléchargez les données</BoutonStandard
            >
          </div>
        </div>
        <LoadingWrapper
          v-if="
            departementChoisi != '' &&
            echelleGeographiqueChoisie != null &&
            pointsApiNecessaires.length > 0
          "
          :data_loaded="!chargementDonnées"
        >
          <TableauDeDonnées
            v-if="donnéesDuTableauDepartement?.length > 0"
            :données="donnéesDuTableauDepartement"
            class="tableau-donnees"
            cacherPagination="true"
          />
          <TableauDeDonnées
            v-if="donnéesDuTableau?.length > 0"
            :données="donnéesDuTableau"
            class="tableau-donnees"
          />
          <p v-else>Pas de données pour {{ departementChoisi.localeName }}</p>
        </LoadingWrapper>
      </div>
    </template>
  </grilleStandard>
  <modalAttention
    :ouvrirModal="ouvrirModaleAttention"
    @fermerModal="this.ouvrirModaleAttention = false"
  >
    Vous devez choisir un ou plusieurs indicateurs pour télécharger les
    données</modalAttention
  >
</template>

<script>
import barreRechercheDepartements from "@/composants/barreRechercheDepartements.vue";

import TableauDeDonnées from "@packages/ui-components/src/composants/visualisation/TableauDeDonnées.vue";
import LoadingWrapper from "@packages/ui-components/src/composants/chargement/LoadingWrapper.vue";
import facteurs_de_risques from "@/assets/donnees/facteurs_de_risques.json";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import fonction_api from "@/utils/fonction_api.js";
import exportDataHelper from "@packages/utils/ExportDataHelper.js";
import utils from "@/utils/utils.js";
import modalAttention from "../composants/modalAttention.vue";
import valeurs_indicateurs_departements from "@/assets/donnees/valeurs_indicateurs_departements.json";
import valeurs_indicateurs_iris_departements from "@/assets/donnees/valeurs_indicateurs_iris_departements.json";

export default {
  components: {
    grilleStandard,
    BoutonStandard,
    TableauDeDonnées,
    LoadingWrapper,
    barreRechercheDepartements,
    modalAttention,
  },

  data() {
    return {
      listeIris: [],
      listeCommunes: [],
      listeCommunesDict: {},
      rechercheInput: "",
      list_geo: [],
      departementChoisi: "",
      echelleGeographiqueChoisie: "commune", // iris ou communes
      territoiresChoisis: [],
      parametres: {
        echelle_geographique: [
          { nomIndicateur: "commune", valeurDefaut: true },
          { nomIndicateur: "iris", valeurDefaut: false },
        ],
      },
      selection: {
        indicateursChoisis: [],
        indicateursIrisFiltres: [],
      },
      interaction: {
        valeurRechercheTerritoire: "",
        valeurRechercheIndicateur: "",
      },
      facteurs_de_risques,
      donnéesDuTableau: null,
      donnéesDuTableauDepartement: null,
      chargementDonnées: false,
      apiResults: {},
      donnees: [],
      ouvrirModaleAttention: false,
      listeIrisparCommune: null,
    };
  },
  methods: {
    téléchargerLesDonnées() {
      if (
        (this.selection.indicateursChoisis.length === 0) |
        (this.selection.indicateursChoisis === null)
      ) {
        this.ouvrirModaleAttention = true;
        return;
      }
      exportDataHelper.exportToWorkbook(
        [
          {
            donneesJson: this.donnéesDuTableau,
            nomFeuillet: "Indicateurs",
          },
          {
            donneesJson: this.donnéesDuTableauDepartement,
            nomFeuillet: "Territoires de comparaison",
          },
          {
            donneesJson: this.indicateursMetadata,
            nomFeuillet: "Metadonnées",
          },
        ],
        "Données_Obsoalim"
      );
    },
    async rafraichirDonnéesDuTableau() {
      if (
        this.departementChoisi == "" ||
        this.echelleGeographiqueChoisie == null ||
        this.pointsApiNecessaires.length == 0 ||
        this.territoiresChoisis.length == 0
      ) {
        return;
      }
      this.chargementDonnées = true;
      this.donnéesDuTableau = null;
      var apiResults = {};
      var donnees = [...this.listeCommunes];

      this.pointsApiNecessaires.forEach(async (apiPoint) => {
        await fonction_api
          .chercheDonneesObsoalim(
            apiPoint,
            this.departementChoisi.code_territoire.slice(3),
            this.echelleGeographiqueChoisie
          )
          .then((response) => {
            Object.assign(apiResults, response.resultats);
            donnees.forEach((territoire) => {
              Object.keys(response.resultats).forEach((key) => {
                territoire[key] =
                  response.resultats[key][territoire.code_territoire];
              });
            });
          });
      });
      const promises = this.pointsApiNecessaires.map(
        async (apiPoint) =>
          await fonction_api
            .chercheDonneesObsoalim(
              apiPoint,
              this.departementChoisi.code_territoire.slice(3),
              this.echelleGeographiqueChoisie
            )
            .then((response) => response.resultats)
      );
      const apiResultsList = await Promise.all(promises);
      var resultatsApiObj = apiResultsList.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

      donnees = donnees.filter((territoire) =>
        this.territoiresChoisis.some(
          (territoireChoisi) =>
            territoireChoisi.code_territoire === territoire.code_territoire
        )
      );

      var libelles = await fonction_api.getLibellesTerritoires(
        this.departementChoisi.code_territoire,
        this.echelleGeographiqueChoisie
      );

      for (var code_territoire in libelles) {
        libelles[code_territoire] =
          libelles[code_territoire]["libelle_territoire"];
      }
      Object.assign(apiResults, { Libellés: libelles });
      resultatsApiObj = { ...resultatsApiObj, Libellés: libelles };
      this.donnéesDuTableau = exportDataHelper
        .convertApiResultToArray(resultatsApiObj, "code_territoire")
        .map((ligne) => {
          if (
            this.territoiresChoisis.filter(
              (territoire) =>
                territoire.code_territoire === ligne.code_territoire
            ).length > 0
          ) {
            let ligneFiltree = {};
            if (ligne["Libellés"]) {
              console.log("ligne", ligne);
              if (this.echelleGeographiqueChoisie === "iris") {
                ligneFiltree["IRIS"] = ligne["Libellés"];
                ligneFiltree["Communes"] = this.listeCommunes.find(
                  (territoire) =>
                    territoire.code_territoire ===
                    "com" + ligne["code_territoire"].slice(4, 9)
                ).libelle_territoire;
                ligneFiltree["Code INSEE"] = this.listeCommunes
                  .find(
                    (territoire) =>
                      territoire.code_territoire ===
                      "com" + ligne["code_territoire"].slice(4, 9)
                  )
                  .code_territoire.slice(3);
              } else {
                ligneFiltree["Communes"] = ligne["Libellés"];
                ligneFiltree["Code INSEE"] = ligne["code_territoire"].slice(3);
              }
              console.log("ligneFiltree", ligne);
              this.indicateursChoisisCroissants.forEach((indicateur) => {
                if (
                  !ligne[indicateur.ValeurIndicateurAPI] &
                  (ligne[indicateur.ValeurIndicateurAPI] != 0)
                ) {
                  ligneFiltree[indicateur.nomIndicateur] = "Non disponible";
                } else if (ligne[indicateur.ValeurIndicateurAPI] == "") {
                  ligneFiltree[indicateur.nomIndicateur] = "Non disponible";
                } else {
                  ligneFiltree[indicateur.nomIndicateur] =
                    utils.formatterNombre(
                      ligne[indicateur.ValeurIndicateurAPI],
                      indicateur.typeIndicateur
                    ) ?? 0;
                }
              });
            }
            return ligneFiltree;
          }
        })
        .filter((ligne) => ligne !== undefined);
      this.chargementDonnées = false;
      console.log("donnéesDuTableau", this.donnéesDuTableau);
      this.donnéesDuTableauDepartement = this.getDonneesDepartements();
    },
    trierListeObjets(liste, cle) {
      if (liste.length === 0) {
        return liste;
      }
      return liste.sort((a, b) => {
        return a[cle].localeCompare(b[cle]);
      });
    },
    actionClick(resultat) {
      this.departementChoisi = resultat;
      this.rechercheInput = "";
      console.log("Département choisi:", this.departementChoisi);
      this.rafraichirDonnéesDuTableau();
    },
    choisirTouslesIndicateurs() {
      var listeIndicateurs = [];
      this.facteurs_de_risques.forEach((facteur) => {
        if (this.echelleGeographiqueChoisie === "iris") {
          if (facteur.indicateursIris && facteur.lienAncre !== "synthetique") {
            facteur.indicateursIris.forEach((indicateur) => {
              if (indicateur.indicateursynthetique !== true) {
                listeIndicateurs.push(indicateur);
              }
            });
          }
        } else {
          if (facteur.indicateurs && facteur.lienAncre !== "synthetique") {
            facteur.indicateurs.forEach((indicateur) => {
              listeIndicateurs.push(indicateur);
            });
          }
        }
      });
      this.selection.indicateursChoisis = listeIndicateurs;
    },
    getDonneesDepartements() {
      let valeur_departements = [];
      if (this.echelleGeographiqueChoisie === "iris") {
        valeur_departements = valeurs_indicateurs_iris_departements.map(
          (indicateurs) => {
            return {
              code_territoire: indicateurs.code_territoire,
              code_departement:
                indicateurs.code_territoire == "France"
                  ? "France"
                  : indicateurs.code_territoire.slice(3),
              id_indicateur: indicateurs.id_indicateur,
              valeur_indicateur: indicateurs.valeur_indicateur ?? 0,
            };
          }
        );
      } else {
        valeur_departements = valeurs_indicateurs_departements;
      }
      console.log("valeur_departements", valeur_departements);
      let donneesFormateesDepartements = {
        "Territoire de comparaison": this.departementChoisi.nom,
      };
      let donneesFormateesFrance = {
        "Territoire de comparaison": "France",
      };
      this.selection.indicateursChoisis.forEach((indicateur) => {
        console.log("indicateur", indicateur);
        let donnees = valeur_departements.filter(
          (indicateurs) =>
            indicateurs.code_departement ===
              this.departementChoisi.code_territoire.slice(3) &&
            indicateurs.id_indicateur === indicateur.idIndicateur
        );
        donneesFormateesDepartements[indicateur.nomIndicateur] =
          utils.formatterNombre(
            donnees[0].valeur_indicateur,
            indicateur.typeIndicateur
          );
        let donneesFrance = valeur_departements.filter(
          (indicateurs) =>
            indicateurs.code_departement === "France" &&
            indicateurs.id_indicateur === indicateur.idIndicateur
        );
        donneesFormateesFrance[indicateur.nomIndicateur] =
          utils.formatterNombre(
            donneesFrance[0].valeur_indicateur ?? 0,
            indicateur.typeIndicateur
          );
      });

      valeur_departements.filter(
        (indicateurs) =>
          indicateurs.code_departement ===
            this.departementChoisi.code_territoire.slice(3) &&
          this.selection.indicateursChoisis
            .map((indicateur) => indicateur.idIndicateur)
            .includes(indicateurs.id_indicateur)
      );
      return [donneesFormateesDepartements, donneesFormateesFrance];
    },
  },
  mounted() {
    this.echelleGeographiqueChoisie = this.parametres.echelle_geographique.find(
      (echelle) => echelle.valeurDefaut
    ).nomIndicateur;
  },
  computed: {
    texteParDefaut() {
      if (this.departementChoisi === "") {
        return "Recherchez un département";
      } else {
        return this.departementChoisi.localeName;
      }
    },
    cléListeIndicateurs() {
      if (this.echelleGeographiqueChoisie === "iris") {
        return "indicateursIris";
      } else {
        return "indicateurs";
      }
    },
    indicateursIrisFiltres: function () {
      return this.facteurs_de_risques.map((facteur) => {
        return facteur.indicateursIris.filter((indicateur) =>
          indicateur.nomIndicateur
            .toLowerCase()
            .includes(this.interaction.valeurRechercheIndicateur.toLowerCase())
        );
      });
    },
    indicateursChoisisCroissants() {
      const listeCroissante = this.selection.indicateursChoisis;
      listeCroissante.sort((a, b) => {
        return a.nomIndicateur.localeCompare(b.nomIndicateur);
      });
      return listeCroissante;
    },
    indicateursMetadata() {
      return this.selection.indicateursChoisis.map((indicateur) => {
        return {
          "Nom Indicateur": indicateur.nomIndicateur,
          "Source Données (année)":
            indicateur?.sourceDonnees ?? "Non disponible",
        };
      });
    },
    pointsApiNecessaires() {
      var list = [];
      facteurs_de_risques.forEach((facteur) => {
        switch (this.echelleGeographiqueChoisie) {
          case "iris":
            if (facteur.indicateursIris) {
              facteur.indicateursIris.forEach((indicateur) => {
                if (
                  this.selection.indicateursChoisis.some(
                    (ind) => ind.nomIndicateur === indicateur.nomIndicateur
                  ) &&
                  !list.includes(facteur.lienAncre)
                ) {
                  list.push(facteur.lienAncre);
                }
              });
            }
            break;
          case "commune":
            if (facteur.indicateurs) {
              facteur.indicateurs.forEach((indicateur) => {
                if (
                  this.selection.indicateursChoisis.some(
                    (ind) => ind.nomIndicateur === indicateur.nomIndicateur
                  ) &&
                  !list.includes(facteur.lienAncre)
                ) {
                  list.push(facteur.lienAncre);
                }
              });
            }
            break;
        }
      });
      return list;
    },
  },
  watch: {
    departementChoisi() {
      fonction_api
        .getLibellesTerritoires(
          this.departementChoisi.code_territoire,
          "commune"
        )
        .then((result) => {
          this.listeCommunesDict = result;
          this.listeCommunes = Object.values(result);
        })
        .then(
          fonction_api
            .getLibellesTerritoires(
              this.departementChoisi.code_territoire,
              "iris"
            )

            .then((irisResult) => {
              this.listeIris = Object.values(irisResult);
              this.listeIris = this.listeIris.filter(
                (iris) => iris.echelle_geographique === "iris"
              );
              this.listeIris.forEach((iris) => {
                iris.code_commune = "com" + iris.code_territoire.slice(4, 9);
                if (this.listeCommunesDict[iris.code_commune]) {
                  iris.libelle_commune =
                    this.listeCommunesDict[iris.code_commune][
                      "libelle_territoire"
                    ];
                }
              });
            })
            .then(() => {
              setTimeout(() => {
                var listeIrisparCommune = {};
                this.listeIris.forEach((iris) => {
                  if (listeIrisparCommune[iris.libelle_commune]) {
                    listeIrisparCommune[iris.libelle_commune].push(iris);
                  } else {
                    listeIrisparCommune[iris.libelle_commune] = [iris];
                  }
                });
                this.listeIrisparCommune = listeIrisparCommune;
              }, 700);
            })
        );
    },
    echelleGeographiqueChoisie() {
      this.rafraichirDonnéesDuTableau();
    },
    pointsApiNecessaires() {
      this.rafraichirDonnéesDuTableau();
    },
    territoiresChoisis() {
      this.rafraichirDonnéesDuTableau();
    },
    rechercheInput: function (valeur) {
      if (valeur.length >= 1) {
        fonction_api
          .chercheTerritoires(valeur, "departement")
          .then((response) => {
            this.list_geo = response;
            console.log("territoires trouvés", this.list_geo);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.contenu {
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
  padding: 32px 0px;
  align-items: stretch;
}

.titre-sous-titre {
  text-align: left;
}

#composant-recherche {
  position: relative;
  width: 100%;
}
#recherche-barre {
  width: 100%;
  padding: var(--dsem);
  margin: 0px 0;
  box-sizing: border-box;
  border: 1px solid var(--couleur-gris-80);
  background-color: #ffffff;
  font-size: 16px;
  background-size: 20px;
  padding-left: 40px;
}

#recherche-barre:focus {
  outline: none;
  border: 1px solid var(--couleur-gris);
}
#recherche-resultat {
  list-style-type: none;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid var(--couleur-gris-80);
  box-sizing: border-box;
}

#recherche-resultat > li {
  padding: var(--dsem) var(--dsem2);
  cursor: pointer;
  background-color: #ffffff;
}

#recherche-resultat > li:hover {
  padding: var(--dsem) var(--dsem2);
  cursor: pointer;
  background-color: var(--couleur-gris-40);
}

.resultatChoisi {
  background-color: var(--couleur-gris-80) !important;
  border: 1px solid var(--couleur-gris) !important;
}

.resultatChoisi::placeholder {
  color: var(--couleur-blanc);
}

.bloc-reglages {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: stretch;
  gap: var(--dsem4);
}

.titre-et-encadre-reglages {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  min-width: 300px;
  gap: var(--dsem2);
}
.encadre-reglages {
  flex-direction: column;
  text-align: left;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: var(--dsem4);
}

.titre-actif {
  color: var(--couleur-primaire);
  font-weight: bold;
}

.titre {
  cursor: pointer;
}
.encadre-reglages ul.liste-choix-possibles {
  overflow-y: scroll;
  height: 200px;
  padding-left: var(--dsem);
}

ul.sous-liste {
  padding-left: var(--dsem);
}

ul.sous-liste li {
  display: flex;
  flex-direction: row;
  gap: var(--dsem);
}
ul.liste-communes li {
  display: flex;
  flex-direction: row;
  gap: var(--dsem);
}

.titre-encadre {
  display: flex;
  flex-direction: row;
  gap: var(--dsem2);
  text-align: left;
  border-bottom: 1px solid var(--couleur-gris);
  padding: var(--dsem) var(--dsem2);
}

.recherche-barre-reglages {
  width: 100%;
  padding: var(--dsem);
  box-sizing: border-box;
  border-bottom: 1px solid var(--couleur-gris)-20;
  background-color: var(--couleur-gris-20);
  font-size: 16px;
  background-size: 20px;
  padding-left: 40px;
}

.boutons-encadre {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: stretch;
  text-align: left;
}

.bouton-selection {
  flex-grow: 1;
  text-align: center;
}

.liste-elements-choisis {
  display: flex;
  flex-direction: row;
  gap: var(--dsem);
  text-align: left;
  height: 70px;
  overflow-y: scroll;
  padding: var(--dsem);
  flex-wrap: wrap;
}

.liste-elements-choisis li.elements-choisis {
  background-color: var(--couleur-gris-40);
  display: flex;
  flex-direction: row;
  gap: var(--dsem);
  text-align: left;
  padding: 0 var(--dsem);
  border-radius: 2px;
  height: fit-content;
}

li.elements-choisis ::slotted(svg) {
  cursor: pointer;
  color: white;
  stroke: white;
  fill: white;
}

.liste-elements-choisis li.elements-choisis:hover {
  background-color: var(--couleur-gris-80);
}

.bouton-actions {
  display: flex;
  flex-direction: row;
  gap: var(--dsem2);
  text-align: left;
}

.logo-croix {
  cursor: pointer;
  color: white;
  stroke: white;
  fill: white;
}

.loupe {
  position: absolute;
  top: 30%;
  left: 10px;
}

.tableau-donnees >>> .vtl-thead-th {
  min-width: 200px;
}
</style>
