import liste_departements from "@/assets/donnees/liste_departements.json";

export const trouverDepartement = (codeDepartement) => {
  console.log(liste_departements);
  var departement = liste_departements.find(
    (departement) => departement.code === codeDepartement
  );
  return departement;
};

export function formatterNombre(value, type) {
  if (value === null) {
    return "N/A";
  } else if (type === "pourcentage") {
    return new Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 2,
      style: "percent",
    }).format(value);
  } else if (type === "nombre") {
    return new Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 2,
    }).format(value);
  } else if (type === "entier") {
    return new Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 0,
    }).format(value);
  } else if (type === "euros") {
    return new Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "EUR",
    }).format(value);
  } else if (type === "temps") {
    return new Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 2,
      style: "unit",
      unit: "second",
    }).format(value);
  } else {
    return value;
  }
}

export default { trouverDepartement, formatterNombre };
